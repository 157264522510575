import { useState, useEffect } from "react";
import { alpha, Box, Button, CircularProgress, Divider, TextField, Typography } from "@mui/material";
import { FileUpload } from "../../components";
import { getNameFromPath } from "../../utils";
import { IFileUploadHandle, IPreviewSource, ModalType, SubmitButtonType, User } from "../../utils/types";
import { useCreateSourceMutation, useEditSourceMutation } from "../../api/apiSlice";
import React from "react";
import toast from "react-hot-toast";
import CustomSelect, { Option } from "../../components/CustomSelect";

type CreateOrEditSourceProps = {
  isModalOpen: boolean;
  handleClose: () => void;
  modalType: ModalType;
  source: IPreviewSource | null;
  fileUploadRef: React.RefObject<IFileUploadHandle>;
};

export const CreateOrEditSource = (props: CreateOrEditSourceProps) => {
  const { handleClose, modalType, source, fileUploadRef } = props;
  const [isUploadingImage, setIsUploadingImage] = useState<boolean>(false);
  const [sourceData, setSourceData] = React.useState<Partial<IPreviewSource> | null>(source || null);
  const [aurthorValue, setAurthorValue] = React.useState<Option[]>([]);
  const [isValidData, setIsValidData] = React.useState<boolean>(false);
  const [buttonClicked, setButtonClicked] = React.useState<SubmitButtonType | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [fileUploadError, setFileUploadError] = useState<string | null>(null);

  const [createSource, createResult] = useCreateSourceMutation();
  const [editSource, editResult] = useEditSourceMutation();
  const { isLoading: isLoadingCreate, isSuccess } = createResult;
  const { isSuccess: isEditSuccess, isLoading: isEditLoading } = editResult;

  const isLoading = isLoadingCreate || isEditLoading;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSourceData({ ...sourceData, [event.target.name]: event.target.value });

    // Only show error for required fields
    if (event.target.name === "name") {
      if (event.target.value) {
        setError(null);
        return;
      }
      setError("Name is required");
    }
  };

  const getFileUploadError = (error: string | null) => {
    setFileUploadError(error);
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const buttonName = e.nativeEvent.submitter.name as SubmitButtonType;
    setButtonClicked(buttonName);
    const payload = {
      ...sourceData,
      authors: aurthorValue.map(item => {
        if (item.id === item.label) {
          return { name: item.label };
        }
        return { id: item.id, name: item.label };
      }),
    };

    if (!payload?.name) {
      return;
    }

    let uploadedFileName: string | null = null;

    if (fileUploadRef.current) {
      setIsUploadingImage(true);
      uploadedFileName = await fileUploadRef.current.uploadFile();
      setIsUploadingImage(false);
      if (uploadedFileName === "error") return;
    }

    const fileName = getNameFromPath(payload?.cover || "");

    // the update on the icon prop is not as the original: need to update icon
    const isNotPristine = fileName !== uploadedFileName;

    switch (modalType) {
      case "Create":
        await createSource({
          cover: uploadedFileName || "",
          name: payload.name,
          isbn: payload.isbn,
          authors: payload.authors,
        })
          .unwrap()
          .then(() => {
            toast.success("Source created successfully");
          })
          .catch(error => {
            toast.error(error.data || "Failed to create source");
          });
        break;

      case "Edit":
        if (!payload.id || payload.published === undefined) break;

        await editSource({
          id: payload.id,
          isbn: payload.isbn,
          cover: isNotPristine ? uploadedFileName || "" : fileName || "",
          name: payload.name,
          authors: payload.authors,
          published: buttonName === "saveAsUnpublished" ? 0 : -1,
        })
          .unwrap()
          .then(() => {
            toast.success("Changes saved successfully");
          })
          .catch(error => {
            toast.error(error?.data || "Failed to save changes");
          });
        break;
    }
  };

  useEffect(() => {
    if (source && source.authors.length > 0) {
      setAurthorValue(source.authors.map((item: User) => ({ id: item.id, label: item.name })));
    }
  }, [source]);

  const handleSelectChange = (event: React.SyntheticEvent<Element, Event>, newValue: any) => {
    setAurthorValue(newValue);
  };

  useEffect(() => {
    if (isSuccess || isEditSuccess) {
      handleClose();
    }
  }, [handleClose, isEditSuccess, isSuccess]);

  useEffect(() => {
    if (sourceData?.name?.trim() && !fileUploadError) {
      setIsValidData(true);
    } else {
      setIsValidData(false);
    }
  }, [fileUploadError, sourceData?.name]);

  return (
    <form onSubmit={onSubmit}>
      <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ height: "100%" }}>
        <Box display="flex" flexDirection={"column"} gap={2}>
          <TextField
            required
            inputProps={{ maxLength: 255 }}
            onChange={handleOnChange}
            onBlur={handleOnChange}
            label="Name"
            name="name"
            fullWidth
            value={sourceData?.name ?? ""}
            error={!!error}
            helperText={error}
            size="small"
          />
          <TextField
            inputProps={{ maxLength: 255 }}
            onChange={handleOnChange}
            label="ISBN"
            name="isbn"
            fullWidth
            value={sourceData?.isbn ?? ""}
            size="small"
          />
          <CustomSelect
            onChange={(event, newValue) => handleSelectChange(event, newValue)}
            value={aurthorValue}
            multiple
            freeSolo
            processResponse={res => {
              return (
                res.data?.data
                  ?.map((item: any) => ({
                    id: item?.id,
                    label: item.name,
                  }))
                  ?.sort((a: any, b: any) => a.label.localeCompare(b.label)) ?? []
              );
            }}
            InputProps={{
              label: "Authors",
            }}
            url={"/api/authors?published=1"}
            getQueryParams={(name: string) => ({ name: name.trim() })}
            options={[]}
          />
          <FileUpload imgSrc={sourceData?.cover} ref={fileUploadRef} getFileUploadError={getFileUploadError} />
        </Box>

        <Box>
          <Divider />
          <Box display="flex" justifyContent="space-between" sx={{ p: 3, bottom: 0, gap: 2 }}>
            <Button
              disabled={isLoading || isUploadingImage || !isValidData}
              sx={{ width: 100, borderRadius: "20px" }}
              variant="contained"
              color="primary"
              type="submit"
              name="save"
            >
              {(isLoading || isUploadingImage) && buttonClicked === "save" && <CircularProgress size={20} />}
              Save
            </Button>
            <Button
              disabled={isLoading || isUploadingImage || !isValidData}
              sx={theme => ({ flex: 1, borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
              variant="contained"
              color="primary"
              type="submit"
              name="saveAsUnpublished"
            >
              {(isLoading || isUploadingImage) && buttonClicked === "saveAsUnpublished" && <CircularProgress size={20} />}
              Save as Unpublished
            </Button>
            <Button disableElevation variant="outlined" sx={{ borderRadius: "20px" }} onClick={handleClose} type="button">
              <Typography sx={{ fontSize: "16px" }} color="textPrimary">
                Cancel
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
};
