import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import Login from "../Login";
import Register from "../Register";
import { useAuth } from "./authController";
import LogoIcon from "../../images/logo.svg";

const Auth = () => {
  const [isLogin, setIsLogin] = useState(true);
  const { auth } = useAuth();

  if (auth.login.accessToken) {
    return <Navigate to="/content" replace />;
  }
  return (
    <Box
      sx={theme => ({
        backgroundImage: { xs: "", md: "url(/images/background.jpg)" },
        width: "100vw",
        height: "100vh",
        boxSizing: "border-box",
        p: {
          xs: 1,
          sm: 20,
        },
      })}
    >
      <Box
        sx={theme => ({
          width: "100%",
          maxWidth: "464px",
          background: theme.palette.background.default,
          borderRadius: 1,
          p: { xs: 1, md: 6 },
          boxSizing: "border-box",
        })}
      >
        <Box display="flex" sx={{ mb: 5 }}>
          <Box display="flex" alignItems="center" flex={1} gap={4}>
            <img alt="logo" src={LogoIcon} />
            <Typography variant="subtitle2">Wisdom</Typography>
          </Box>
          <Box sx={{ flex: 3 }}>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              {/* <Typography variant="body1">{isLogin ? "New user?" : ""}</Typography> */}
              <Button
                disableRipple
                disableFocusRipple
                onClick={() => setIsLogin(true)}
                sx={{
                  ml: 1,
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                component="span"
              >
                <Typography sx={theme => ({ color: theme.palette.primary.dark })} variant="body1">
                  {/* {isLogin ? "Create account" : "Signin"} */}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
        {isLogin ? <Login /> : <Register />}
      </Box>
    </Box>
  );
};

export default Auth;
