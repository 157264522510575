import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface RegisterState {
  name: string;
  email: string;
  password: string;
  avatar: string;
}

export const register = async (formData: RegisterState) => {
  try {
    const { data } = await axios.post(`/api/auth/register`, formData);
    return { data };
  } catch (error) {
    return { error };
  }
};

export const useRegister = () => {
  const [formData, setFormData] = useState<RegisterState>({
    name: "",
    email: "",
    password: "",
    avatar: "",
  });

  const [errors, setErrors] = useState<RegisterState>({
    name: "",
    email: "",
    password: "",
    avatar: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const navigate = useNavigate();
  const validate = (): boolean => {
    let valid = true;
    if (!formData.name) {
      valid = valid && false;
      setErrors(e => ({ ...e, name: "Name is required" }));
    }
    if (!formData.email) {
      valid = valid && false;
      setErrors(e => ({ ...e, email: "Email is required" }));
    }
    if (!formData.password) {
      valid = valid && false;
      setErrors(e => ({ ...e, password: "Password is required" }));
    }
    return valid;
  };
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    const { error } = await register(formData);
    if (error) {
      return;
    }
    navigate("/home");
  };
  return { formData, handleChange, handleSubmit, navigate, errors };
};
