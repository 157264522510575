import { FC } from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { Auth, Wrapper, Users } from "./containers";
import Cultures from "./containers/Cultures";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Quotes from "./containers/Quotes"; // Using QuotesV2 instead for now. TODO: Remove this line after QuotesV2 is fully tested and working
import QuotesV2 from "./containers/Quotes/QuotesV2";
import { Tags } from "./containers/Tags";
import { Authors } from "./containers/Authors/Authors";
import { References } from "./containers/References";
import Sources from "./containers/Sources/Sources";
import { useHasAdminRole } from "./utils/hooks";
import NotFoundPage from "./containers/ErrorStates/404";
import UnauthorizedPage from "./containers/ErrorStates/403";
import Languages from "./containers/Languages";

const routes = [
  {
    path: "/",
    Component: Auth,
    authenticate: false,
  },
  {
    path: "/users",
    Component: Users,
    authenticate: true,
  },
  {
    path: "/cultures",
    Component: Cultures,
    authenticate: true,
  },
  {
    path: "/content",
    Component: QuotesV2,
    authenticate: true,
  },
  {
    path: "/quotes",
    Component: Quotes,
    authenticate: true,
  },
  {
    path: "/tags",
    Component: Tags,
    authenticate: true,
  },
  {
    path: "/authors",
    Component: Authors,
    authenticate: true,
  },
  {
    path: "/references",
    Component: References,
    authenticate: true,
  },
  {
    path: "/sources",
    Component: Sources,
    authenticate: true,
  },
  {
    path: "/languages",
    Component: Languages,
    authenticate: true,
  },
  {
    path: "/403",
    Component: UnauthorizedPage,
    authenticate: true,
  },
  {
    path: "*",
    Component: NotFoundPage,
    authenticate: true,
  },
];

const Index: FC = () => {
  const canManageUsers = useHasAdminRole();

  return (
    <BrowserRouter>
      <Routes>
        {routes.map(({ path, Component, authenticate }) => {
          if (path === "/users" && !canManageUsers) {
            return <Route key={path} path={path} element={<UnauthorizedPage />} />;
          }
          return (
            <Route
              key={path}
              path={path}
              element={
                authenticate ? (
                  <Wrapper>
                    <Component />
                  </Wrapper>
                ) : (
                  <Component />
                )
              }
            />
          );
        })}
      </Routes>
    </BrowserRouter>
  );
};

export default Index;
