import { Avatar as MUIAvatar, AvatarProps, Box, CircularProgress, IconButton } from "@mui/material";
import unknownUserIcon from "./unknownUserIcon.svg";
import { FC } from "react";

const darkModeColors = {
  RawSienna: "#BF884D",
  JuneBud: "#C9C959",
  Mantis: "#6BC655",
  MediumAquamarine1: "#6BC6AA",
  HanBlue: "#5283C4",
  RoyalBlue: "#4040C2",
  MediumVioletRed: "#BA4A83",
  DeepCarminePink: "#BA4A46",
  AppleGreen: "#94C756",
  MediumAquamarine2: "#6BC68A",
  BlueViolet: "#7C44C2",
  MediumOrchid: "#BA4AC3",
  crimson: "#DC143C",
  gold: "#FFD700",
  chartreuse: "#7FFF00",
  turquoise: "#40E0D0",
  skyBlue: "#87CEEB",
  slateBlue: "#6A5ACD",
  orchid: "#DA70D6",
  salmon: "#FA8072",
  darkOrange: "#FF8C00",
  limeGreen: "#32CD32",
  mediumTurquoise: "#48D1CC",
  steelBlue: "#4682B4",
  mediumPurple: "#9370DB",
  paleVioletRed: "#DB7093",
  coral: "#FF7F50",
  darkSalmon: "#E9967A",
  yellowGreen: "#9ACD32",
  darkCyan: "#008B8B",
  cornflowerBlue: "#6495ED",
  mediumOrchid: "#BA55D3",
  hotPink: "#FF69B4",
  tomato: "#FF6347",
  sandyBrown: "#F4A460",
  lime: "#00FF00",
  cyan: "#00FFFF",
  dodgerBlue: "#1E90FF",
  mediumVioletRed: "#C71585",
  deepPink: "#FF1493",
  orangeRed: "#FF4500",
  darkGoldenRod: "#B8860B",
};

const stringToColor = (name: string = "") => {
  const alphabet: any = {
    A: darkModeColors.RawSienna,
    B: darkModeColors.JuneBud,
    C: darkModeColors.Mantis,
    D: darkModeColors.MediumAquamarine1,
    E: darkModeColors.HanBlue,
    F: darkModeColors.RoyalBlue,
    G: darkModeColors.MediumVioletRed,
    H: darkModeColors.DeepCarminePink,
    I: darkModeColors.AppleGreen,
    J: darkModeColors.MediumAquamarine2,
    K: darkModeColors.BlueViolet,
    L: darkModeColors.MediumOrchid,
    M: darkModeColors.mediumPurple,
    N: darkModeColors.paleVioletRed,
    O: darkModeColors.coral,
    P: darkModeColors.darkSalmon,
    Q: darkModeColors.yellowGreen,
    R: darkModeColors.darkCyan,
    S: darkModeColors.cornflowerBlue,
    T: darkModeColors.mediumOrchid,
    U: darkModeColors.hotPink,
    V: darkModeColors.tomato,
    W: darkModeColors.sandyBrown,
    X: darkModeColors.lime,
    Y: darkModeColors.cyan,
    Z: darkModeColors.dodgerBlue,
  };

  let firstNameCharacter: string = name?.split(" ")[0][0]?.toUpperCase();

  return alphabet[firstNameCharacter] || darkModeColors.deepPink;
};

const stringAvatar = (name: string, sx: any) => {
  const hasTwoNames = name?.split(" ").length > 1;
  const hasOneName = name?.split(" ").length === 1;
  if (!hasTwoNames && !hasOneName) {
    return {
      src: unknownUserIcon,
    };
  }

  if (hasOneName) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        backgroundColor: stringToColor(name),
        color: "white",
        width: 32,
        height: 32,
        ...sx,
      },
      children: `${name?.split(" ")[0][0]}`,
    };
  }

  return {
    sx: {
      bgcolor: stringToColor(name),
      backgroundColor: stringToColor(name),
      color: "white",
      width: 32,
      height: 32,
      ...sx,
    },
    children: hasTwoNames ? `${name?.split(" ")[0][0]}${name?.split(" ")[1][0]}` : `${name?.split(" ")[0][0]}`,
  };
};

interface Props extends AvatarProps {
  name: string;
  loading?: boolean;
  style?: any;
  hideIconWrapper?: boolean;
}

const IconWrapper: FC<any> = ({ wrap, children, style, hideIconWrapper }) => {
  if (hideIconWrapper) {
    return <>{children}</>;
  }

  if (wrap) {
    return <IconButton style={style}>{children}</IconButton>;
  }
  return <Box style={{ ...style, padding: 8 }}>{children}</Box>;
};

export const Avatar = (props: Props) => {
  const { name, loading, style = {}, hideIconWrapper, sx, ...restProps } = props;
  const { margin, marginLeft, marginRight, ...styles } = style;
  return (
    <IconWrapper wrap={stringAvatar(name, sx).src} style={{ margin, marginLeft, marginRight }} hideIconWrapper={hideIconWrapper}>
      {loading ? (
        <MUIAvatar
          style={{
            fontSize: 14,
            width: 32,
            height: 32,
            textAlign: "center",
            fontWeight: 500,
            ...styles,
          }}
        >
          <CircularProgress size={12} />
        </MUIAvatar>
      ) : (
        <MUIAvatar
          {...stringAvatar(name, sx)}
          {...restProps}
          style={{
            fontSize: 14,
            width: stringAvatar(name, sx).src ? (name?.toLowerCase() === "unassigned" ? 32 : 20) : 32,
            height: stringAvatar(name, sx).src ? (name?.toLowerCase() === "unassigned" ? 32 : 20) : 32,
            textAlign: "center",
            fontWeight: 500,
            ...styles,
          }}
        />
      )}
    </IconWrapper>
  );
};
