import React, { useState, useEffect, useMemo, ChangeEvent, useCallback } from "react";
import Autocomplete, { AutocompleteProps, createFilterOptions } from "@mui/material/Autocomplete";
import { CircularProgress, TextField, TextFieldProps } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import { debounce } from "../utils/debounce";

export interface Option {
  id: number | string;
  label: string;
  inputValue?: string;
  _original?: any;
}

interface CustomSelectProps
  extends Omit<AutocompleteProps<Option, boolean | undefined, boolean | undefined, boolean | undefined>, "renderInput"> {
  url: string;
  getQueryParams: (searchValue: string) => Record<string, string>;
  processResponse: (res: AxiosResponse) => Option[];
  InputProps?: any;
  idKey?: string;
  disableClearable?: boolean;
}

// Putting a random high number to avoid pagination and second request calls
const PER_PAGE_COUNT = 99999;

const filter = createFilterOptions<Option>();

const CustomSelect: React.FC<CustomSelectProps> = ({
  url,
  getQueryParams,
  onChange,
  InputProps,
  processResponse,
  idKey = "id",
  ...props
}: any) => {
  const [_, setOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const options = useMemo(() => _, [_]);

  const fetchOptions = useCallback(
    async (inputValue: string) => {
      setLoading(true);
      try {
        let options = [];

        const queryParams = inputValue ? getQueryParams(inputValue) : {};
        const firstResponse = await axios.get(url, {
          params: {
            ...queryParams,
            per_page: PER_PAGE_COUNT,
          },
        });

        options = processResponse(firstResponse);
        const total = firstResponse.data.total;

        // If total is somehow greater than PER_PAGE_COUNT, fetch all the data based on the total count
        if (total > PER_PAGE_COUNT) {
          const secondResponse = await axios.get(url, {
            params: {
              ...queryParams,
              per_page: total,
            },
          });
          options = processResponse(secondResponse);
        }

        setOptions(options);
      } catch (error) {
        console.error("Error fetching options:", error);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [url], // we don't want to re-run this effect on every render
  );

  useEffect(() => {
    if (url) {
      fetchOptions("");
    }
  }, [fetchOptions, url]);

  // Since we are fetching options on every key stroke, we need to debounce the fetchOptions function
  // This prevents the function from being called too frequently
  const debouncedFetchOptions = debounce(fetchOptions, 500);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onInputChange = (_: ChangeEvent<HTMLInputElement>, inputValue: string, reason: string) => {
    if (reason === "input" && url) {
      debouncedFetchOptions(inputValue);
    }
  };

  return (
    <Autocomplete
      {...props}
      multiline="true"
      options={url ? options : props?.options}
      loading={loading}
      disablePortal
      // onInputChange={onInputChange} // Relying the client to handle the filtering since we are fetching all the data
      isOptionEqualToValue={(option: Option, value: Option) => option.id === value.id}
      getOptionLabel={(option: Option | string) => {
        if (typeof option === "string") {
          return option;
        }
        return option.label;
      }}
      onChange={(event, newValue: Option[] | Option, reason) => {
        // If freeSolo is enabled, and the user selects the "Add" option, replaace it with the input value
        const newValues =
          props.multiple && props.freeSolo && Array.isArray(newValue)
            ? newValue.map(value => {
                return {
                  ...value,
                  label: value.inputValue || value.label,
                };
              })
            : newValue;
        onChange && onChange(event, newValues, reason);
      }}
      disableClearable={loading || props.disableClearable} // Disable clear icon when loading
      filterOptions={(options: Option[], params) => {
        const filtered = filter(options, params);

        // If freeSolo is enabled, we need to add the option to create a new value
        if (props.freeSolo) {
          const { inputValue: value } = params;

          // Remove trailing spaces and full stops added at the end of the input when the user presses space multiple times
          const inputValue = value.trim().replace(/\.$/, "");
          const isExisting = options.some(option => inputValue.toLocaleLowerCase() === option.label.toLocaleLowerCase());
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              id: inputValue,
              inputValue,
              label: `Add "${inputValue}"`,
            });
          }
        }

        return filtered;
      }}
      renderInput={(params: TextFieldProps) => (
        <TextField
          {...InputProps}
          {...params}
          variant="standard"
          sx={{
            mt: 2,
            mb: 1,
            ...params?.sx,
            pr: 0,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps?.endAdornment}
              </React.Fragment>
            ),
          }}
          error={InputProps.error}
          helperText={InputProps.helperText}
        />
      )}
    />
  );
};

export default CustomSelect;
