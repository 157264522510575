import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "rgb(14, 13, 17)", // Webapp background color
    },
    primary: {
      main: "rgb(111, 90, 239)", // Webapp hover color
      contrastText: "rgb(255, 255, 255)", // Primary text color
    },
    secondary: {
      main: "rgb(169, 166, 182)", // Secondary text color
      contrastText: "rgb(255, 255, 255)", // Assuming secondary buttons have primary text color
    },
    text: {
      primary: "rgb(255, 255, 255)", // Primary text color
      secondary: "rgb(169, 166, 182)", // Secondary text color
    },
    action: {
      hover: "rgb(111, 90, 239)", // Webapp hover color
      active: "rgb(85, 81, 101)", // Highlight color
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(28, 27, 34)", // Webapp card color
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "rgb(111, 90, 239)", // Webapp hover color
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: "rgb(85, 81, 101)", // Highlight color for active buttons
          color: "rgb(255, 255, 255)", // Primary text color
        },
      },
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 16,
    subtitle1: {
      fontSize: 24,
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: 32,
      fontFamily: "PPHatton",
      fontWeight: "lighter",
    },
    caption: {
      fontSize: 12,
      lineHeight: "16px",
    },
    button: {
      textTransform: "none",
    },
    body1: {
      fontSize: 16,
    },
  },
});

export default theme;
