import React from "react";
import { alpha, Box, Button, Divider, Typography } from "@mui/material";
import { IPreviewCulture, TabKeyEnum, TabLabelEnum } from "../../utils/types";
import { userFriendlyDateTimestamp } from "../../utils";
import { TextView, TextViewProps } from "../../components/TextView/TextView";
import { useHasPermission } from "../../utils/hooks";
import { UserPermissions } from "../../utils/permissions";

interface Props {
  activeTab: TabKeyEnum;
  culture: IPreviewCulture | null;
  handleClose: () => void;
  handleEditCulture: () => void;
  handlePublishCulture: () => void;
  handleUnpublishCulture: () => void;
  handleReviewCulture: () => void;
}

interface TextViewInterface extends TextViewProps {
  visible?: boolean;
}

export const ViewCulture: React.FC<Props> = props => {
  const { culture, handleClose, handleEditCulture, handlePublishCulture, activeTab, handleReviewCulture } = props;

  const canApproveCulture = useHasPermission(UserPermissions.APPROVE_PUBLISH_CULTURES);
  const canEditCulture = useHasPermission(UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_CULTURES);

  const showPublishButton = activeTab === TabKeyEnum.ReadyForReview && canApproveCulture;
  const showEditButton = [TabKeyEnum.ReadyForReview, TabKeyEnum.Unpublished].includes(activeTab) && canEditCulture;
  const showReadyForReviewButton = activeTab === TabKeyEnum.Unpublished && canEditCulture;

  if (!culture) {
    return (
      <Typography variant="h5" component="h2">
        No culture selected
      </Typography>
    );
  }

  const textViews: TextViewInterface[] = [
    { label: "Culture", value: culture.culture },
    {
      label: "Published",
      value:
        culture.published === 0
          ? TabLabelEnum.Unpublished
          : culture.published === -1
            ? TabLabelEnum.ReadyForReview
            : TabLabelEnum.Published,
    },
    { label: "Created At", value: userFriendlyDateTimestamp(culture.createdAt) },
    { label: "Created By", value: culture.createdBy?.name },
    { label: "Updated At", value: userFriendlyDateTimestamp(culture.updatedAt) },
    { label: "Updated By", value: culture.updatedBy?.name },
    { label: "Deleted At", value: userFriendlyDateTimestamp(culture.deletedAt), visible: !!culture.deletedAt },
    { label: "Icon", value: culture.icon ? <img alt="icon" height={100} src={`/api${culture.icon}`} loading="lazy" /> : null },
  ];

  const alignButtonsEnd = !showPublishButton && !showEditButton && !showReadyForReviewButton;

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ width: "100%" }}>
      <Box>
        {textViews.map(textView =>
          textView.visible === undefined || textView.visible ? (
            <TextView key={textView.label} label={textView.label} value={textView.value} />
          ) : null,
        )}
      </Box>
      <Box sx={{ gap: 2 }} display="flex" flexDirection="column" justifyContent="space-between">
        <Divider />
        <Box
          display="flex"
          justifyContent={alignButtonsEnd ? "flex-end" : "space-between"} // If no buttons are visible, align to the right for cancel button
          sx={{ position: "relative", bottom: 0, gap: 3 }}
        >
          {showPublishButton && (
            <Button
              onClick={handlePublishCulture}
              sx={theme => ({ borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
              disableElevation
              color="primary"
              variant="contained"
              type="button"
            >
              Approve & Publish
            </Button>
          )}
          {showReadyForReviewButton && (
            <Button
              onClick={handleReviewCulture}
              sx={theme => ({ borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
              disableElevation
              color="primary"
              variant="contained"
              type="button"
            >
              Ready For Review
            </Button>
          )}
          {showEditButton && (
            <Button
              onClick={handleEditCulture}
              sx={{ flex: 1, borderRadius: "20px" }}
              disableElevation
              color="primary"
              variant="contained"
              type="button"
            >
              Edit
            </Button>
          )}
          <Button disableElevation variant="outlined" sx={{ borderRadius: "20px" }} onClick={handleClose} type="button">
            <Typography sx={{ fontSize: "16px" }} color="textPrimary">
              Cancel
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
