import { Chip, MenuItem, Stack } from "@mui/material";
import { OneLiner } from "../../components/Table/Table";
import { ActionCell } from "../../components/ActionCell/ActionCell";
import { userFriendlyDateTimestamp } from "../../utils";
import { MouseEvent, useCallback, useMemo, useState } from "react";
import { UserTabEnum } from "./types";
import { useHasPermission } from "../../utils/hooks";
import { UserPermissions } from "../../utils/permissions";
import { TableMenuItemInterface } from "../../utils/types";

interface Params {
  activeTab: UserTabEnum;
  hoveredRow: number;
  setHoveredRow: (rowIndex: number) => void;
  onClickActivate: () => void;
  onClickInactivate: () => void;
  onClickEditUser: () => void;
  onClickPreview: () => void;
}
export const useGetUsersTableColumns = (params: Params) => {
  const { setHoveredRow, hoveredRow, onClickActivate, onClickInactivate, onClickEditUser, activeTab, onClickPreview } = params;

  const [shouldCloseMenuSelection, setShouldCloseMenuSelection] = useState<boolean>(false);

  const canEditUser = useHasPermission(UserPermissions.EDIT_USERS);
  const canActivateInactivateUsers = useHasPermission(UserPermissions.ACTIVATE_INACTIVATE_USERS);

  const handleClickPreview = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      onClickPreview();
    },
    [onClickPreview, setShouldCloseMenuSelection],
  );

  const handleClickInactivate = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      onClickInactivate();
    },
    [onClickInactivate, setShouldCloseMenuSelection],
  );

  const handleClickActivate = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      onClickActivate();
    },
    [onClickActivate, setShouldCloseMenuSelection],
  );

  const handleClickEditUser = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      onClickEditUser();
    },
    [onClickEditUser, setShouldCloseMenuSelection],
  );

  const columns = useMemo(() => {
    const menuItems: TableMenuItemInterface[] = [
      {
        label: "Preview",
        onClick: handleClickPreview,
        visible: true,
      },
      {
        label: "Inactivate",
        onClick: handleClickInactivate,
        visible: activeTab !== UserTabEnum.InActive && canActivateInactivateUsers,
      },
      {
        label: "Activate",
        onClick: handleClickActivate,
        visible: activeTab === UserTabEnum.InActive && canActivateInactivateUsers,
      },
      {
        label: "Edit",
        onClick: handleClickEditUser,
        visible: canEditUser,
      },
    ];

    return [
      { accessorKey: "id", header: "User ID", size: 150, Cell: ({ row }: any) => <OneLiner text={row.original.id} /> },
      { accessorKey: "name", header: "Name", size: 180, Cell: ({ row }: any) => <OneLiner text={row.original.name} /> },
      { accessorKey: "email", header: "Email", size: 200, Cell: ({ row }: any) => <OneLiner text={row.original.email} /> },
      {
        accessorKey: "roles",
        header: "Roles",
        size: 300,
        Cell: ({ row }: any) => (
          <Stack sx={{ cursor: "pointer" }} direction="row" spacing={1}>
            {row.original?.roles?.length ? (
              row.original?.roles?.map(({ role, id }: any) => {
                return <Chip size="small" key={id} label={role} />;
              })
            ) : (
              <></>
            )}
          </Stack>
        ),
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        Cell: ({ row }: any) => <OneLiner text={userFriendlyDateTimestamp(row.original.createdAt)} />,
      },
      {
        accessorKey: "updatedAt",
        header: "Updated At",
        Cell: ({ row }: any) => <OneLiner text={userFriendlyDateTimestamp(row.original.updatedAt)} />,
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ row }: any) => <OneLiner text={row.original.status} />,
      },
      {
        accessorKey: "lastLoginAt",
        header: "Last Login",
        Cell: ({ row }: any) => <OneLiner text={userFriendlyDateTimestamp(row.original.lastLoginAt)} />,
      },
      {
        accessorKey: "subscribedAt",
        header: "Subscribed Date",
        size: 300,
        Cell: ({ row }: any) => <OneLiner text={userFriendlyDateTimestamp(row.original.subscribedAt)} />,
      },
      {
        accessorKey: "unsubscribedAt",
        header: "Un Subscribed Date",
        size: 300,
        Cell: ({ row }: any) => <OneLiner text={userFriendlyDateTimestamp(row.original.unsubscribedAt)} />,
      },
      { accessorKey: "totalShares", header: "Total shares", Cell: ({ row }: any) => <OneLiner text={row.original.totalShares} /> },
      { accessorKey: "totalLikes", header: "Total likes", Cell: ({ row }: any) => <OneLiner text={row.original.totalLikes} /> },
      { accessorKey: "totalHides", header: "Total hides", Cell: ({ row }: any) => <OneLiner text={row.original.totalHides} /> },
      {
        accessorKey: "tags",
        header: "Total tags",
        Cell: ({ row }: any) => <OneLiner text={row.original.tags} />,
      },
      {
        accessorKey: "action",
        id: "action",
        size: 50,
        header: "",
        enableResizing: false,
        muiTableHeadCellProps: () => ({
          sx: {
            visibility: "hidden",
          },
        }),
        Cell: ({ row }: any) => {
          if (hoveredRow !== row.index) return null;
          return (
            <ActionCell
              shouldCloseMenuSelection={shouldCloseMenuSelection}
              setShouldCloseMenuSelection={setShouldCloseMenuSelection}
              hovered={hoveredRow === row.index}
              loading={false}
              activeTab={activeTab}
              onActionButtonClick={(event: MouseEvent<HTMLElement>) => {
                event?.stopPropagation?.();
                setHoveredRow(row.index);
              }}
            >
              {menuItems.map(
                (item, index) =>
                  item.visible && (
                    <MenuItem key={index} sx={item.sx} onClick={item.onClick}>
                      {item.label}
                    </MenuItem>
                  ),
              )}
            </ActionCell>
          );
        },
      },
    ];
  }, [
    handleClickPreview,
    handleClickInactivate,
    activeTab,
    canActivateInactivateUsers,
    handleClickActivate,
    handleClickEditUser,
    canEditUser,
    shouldCloseMenuSelection,
    hoveredRow,
    setHoveredRow,
  ]);

  return columns;
};
