import React, { FC, useEffect, useState } from "react";
import _ from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import { alpha, Box, Button, CircularProgress, Divider, IconButton, Paper, TextField, Typography } from "@mui/material";

import CustomSelect from "../../components/CustomSelect";
import { useHasPermission } from "../../utils/hooks";
import { UserPermissions } from "../../utils/permissions";
import { publishedStatus } from "../../utils/constants";
import { IQuote, SubmitButtonType } from "../../utils/types";
import { CreateQuoteParams, EditQuoteParams } from "../../api/api.types";

interface Props {
  onSubmit: (data: CreateQuoteParams | EditQuoteParams) => void;
  loading: boolean;
  initialValues?: IQuote | null;
  handleClose: () => void;
  title?: string;
}

const validateQuote = (data: any): { field: string; message: string }[] => {
  const requiredFields = [
    {
      field: "engText",
      message: "Quote is required",
    },
  ];

  const output = requiredFields.filter(({ field }) => !data[field]);
  return output;
};

const Quotes: FC<Props> = props => {
  const { loading, onSubmit, initialValues, title } = props;
  const [quote, setQuote] = useState("");
  const [originalText, setOriginalText] = useState("");
  const [originalLang, setOriginalLang] = useState<any>("");
  const [originalCulture, setOriginalCulture] = useState<any>("");
  const [source, setSource] = useState<any>("");
  const [author, setAuthor] = useState<any>("");
  const [originalTimePeriod, setOriginalTimePeriod] = useState("");
  const [tags, setTags] = useState<any>([]);
  const [reference, setReference] = useState<any>("");
  const [errors, setErrors] = useState<{ field: string; message: string }[]>([]);
  const [selectedSource, setSelectedSource] = useState<any>({});
  const [clickedButton, setClickedButton] = React.useState<SubmitButtonType | null>(null);
  console.log(selectedSource);

  const handleQuoteOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setQuote(event.target.value);
    if (event.target.value) {
      setErrors(errors.filter(({ field }) => field !== "engText"));
      return;
    }
    setErrors([...errors, { field: "engText", message: "Quote is required" }]);
  };

  useEffect(() => {
    if (initialValues) {
      setQuote(initialValues.engText || "");
      setOriginalText(initialValues.origText || "");
      const cultures = initialValues.cultures?.map((cul: any) => ({
        id: cul?.id ?? "",
        label: cul?.culture ?? "",
      }));
      const sources = initialValues.sources?.map((cul: any) => ({ id: cul?.id ?? "", label: cul?.name ?? "" }));
      const authors = initialValues.authors?.map((cul: any) => ({ id: cul?.id ?? "", label: cul?.name ?? "" }));
      const tags = initialValues.tags?.map((cul: any) => ({ id: cul?.id ?? "", label: cul?.tag ?? "" })) ?? [];
      const refs = initialValues.refs?.map((ref: any) => ({ id: ref?.id ?? "", label: ref?.text ?? "" })) ?? [];

      // TODO: Fix and have originalLang be an array like other select properties instead of a string
      const langs =
        typeof initialValues.origLang === "string" && initialValues.origLang
          ? [{ id: initialValues.origLang, label: initialValues.origLang }]
          : Array.isArray(initialValues.origLang)
            ? initialValues.origLang?.map((lang: any) => ({ id: lang, label: lang }))
            : [];

      setOriginalCulture(cultures?.[0] ?? "");
      setSource(sources?.[0] ?? "");
      setAuthor(authors?.[0] ?? "");
      setOriginalTimePeriod(initialValues.origTimePeriod ?? "");
      setTags(tags);
      setReference(refs?.[0] ?? "");
      setOriginalLang(langs?.[0] ?? "");
    }
  }, [initialValues]);

  const canCreateQuotes = useHasPermission(UserPermissions.CREATE_QUOTES);
  const canEditReadyAndUnPublishedQuotes = useHasPermission(UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_QUOTES);

  const showSaveButtons = initialValues ? canEditReadyAndUnPublishedQuotes : canCreateQuotes;

  const handleSubmit = (published: number, buttonType: SubmitButtonType) => {
    setClickedButton(buttonType);
    const requestBody: CreateQuoteParams | EditQuoteParams = {
      engText: quote,
      origText: originalText,
      origLang: [originalLang?.id || originalLang].filter(Boolean)[0] || "", // TODO: Fix and have originalLang be an array like other select properties instead of a string
      cultures: [originalCulture?.id || originalCulture].filter(Boolean),
      sources: [source?.id || source].filter(Boolean),
      authors: [author?.id || author].filter(Boolean),
      origTimePeriod: originalTimePeriod,
      tags: tags.map(({ id }: { id: string | number; label: string }) => id) ?? tags,
      id: initialValues?.id || undefined,
      published,
      refs: [reference?.id || reference].filter(Boolean),
    };

    const validation = validateQuote(requestBody);
    setErrors(validation);
    if (!validation.length) {
      onSubmit(requestBody);
    }
  };

  return (
    <Paper
      sx={theme => ({
        background: alpha(theme.palette.primary.main, 0.12),
        height: "100%",
        display: "flex",
        flexDirection: "column",
        minWidth: 500,
        maxWidth: 500,
        overflowY: "auto",
      })}
    >
      <Box display="flex" justify-content="space-between" alignItems="center" sx={{ padding: "24px 24px 0", width: "100%" }}>
        <Typography sx={{ flex: 1 }}>{title}</Typography>
        <IconButton onClick={props.handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box display="flex" flexDirection="column" flex={1}>
        <Box sx={{ p: 3, flex: 1 }}>
          <TextField
            error={!!errors.find(({ field }) => field === "engText")}
            helperText={errors.find(({ field }) => field === "engText")?.message}
            label="Quote"
            value={quote}
            onChange={handleQuoteOnChange}
            onBlur={handleQuoteOnChange}
            fullWidth
            multiline
            margin="normal"
          />
          <TextField
            label="Original Language Text"
            value={originalText}
            onChange={e => setOriginalText(e.target.value)}
            multiline
            fullWidth
            error={!!errors.find(({ field }) => field === "originalText")}
            helperText={errors.find(({ field }) => field === "originalText")?.message}
            margin="normal"
          />
          <CustomSelect
            onChange={(_, newValue) => {
              setOriginalLang(newValue);
              setErrors(errors.filter(({ field }) => field !== "origLang"));
            }}
            value={originalLang}
            processResponse={res => {
              // Ensure we don't have duplicates to prevent filtering/search from breaking
              return _.uniqBy(
                res.data?.data
                  ?.map((item: any) => ({
                    id: item?.lang,
                    label: item.lang,
                  }))
                  ?.sort((a: any, b: any) => a.label.localeCompare(b.label)) ?? [],
                (item: any) => item.id,
              );
            }}
            InputProps={{
              error: !!errors.find(({ field }) => field === "origLang"),
              helperText: errors.find(({ field }) => field === "origLang")?.message,
              label: "Original Language",
            }}
            url={"/api/langs?published=1"}
            getQueryParams={(name: string) => ({ lang: name.trim() })}
            options={[]}
          />
          <CustomSelect
            onChange={(_, newValue) => setOriginalCulture(newValue)}
            value={originalCulture}
            processResponse={res => {
              return (
                res.data?.data
                  ?.map((item: any) => ({
                    id: item?.id,
                    label: item.culture,
                  }))
                  ?.sort((a: any, b: any) => a.label.localeCompare(b.label)) ?? []
              );
            }}
            InputProps={{
              error: !!errors.find(({ field }) => field === "cultures"),
              helperText: errors.find(({ field }) => field === "cultures")?.message,
              label: "Original Culture",
            }}
            url={"/api/cultures?published=1"}
            getQueryParams={(culture: string) => ({ culture: culture.trim() })}
            options={[]}
          />
          <CustomSelect
            onChange={(_, newValue: any, reason) => {
              setSource(newValue);
              if (newValue?._original?.authors?.length) {
                setAuthor({
                  id: newValue._original?.authors?.[0]?.id,
                  label: newValue._original?.authors?.[0]?.name,
                });
              }
              setSelectedSource(newValue?._original);
            }}
            value={source}
            processResponse={res => {
              return (
                res.data?.data
                  ?.map((item: any) => ({
                    id: item?.id,
                    label: item.name,
                    _original: item,
                  }))
                  ?.sort((a: any, b: any) => a.label.localeCompare(b.label)) ?? []
              );
            }}
            InputProps={{
              error: !!errors.find(({ field }) => field === "source"),
              helperText: errors.find(({ field }) => field === "source")?.message,
              label: "Source",
            }}
            url={"/api/books?published=1"}
            getQueryParams={(name: string) => ({ name: name.trim() })}
            options={[]}
          />
          <CustomSelect
            onChange={(_, newValue) => setAuthor(newValue)}
            value={author}
            processResponse={res => {
              return (
                res.data?.data
                  ?.map((item: any) => ({
                    id: item?.id,
                    label: item.name,
                  }))
                  ?.sort((a: any, b: any) => a.label.localeCompare(b.label)) ?? []
              );
            }}
            InputProps={{
              error: !!errors.find(({ field }) => field === "author"),
              helperText: errors.find(({ field }) => field === "author")?.message,
              label: "Authors",
            }}
            url={"/api/authors?published=1"}
            getQueryParams={(name: string) => ({ name: name.trim() })}
            options={[]}
          />
          <TextField
            variant="standard"
            label="Original Time Period"
            value={originalTimePeriod}
            onChange={e => setOriginalTimePeriod(e.target.value)}
            fullWidth
            margin="normal"
          />
          <CustomSelect
            onChange={(_, newValue) => setTags(newValue)}
            multiple
            filterSelectedOptions
            value={tags}
            processResponse={res => {
              return (
                res.data?.data
                  ?.map((item: any) => ({
                    id: item?.id,
                    label: item.tag,
                  }))
                  ?.sort((a: any, b: any) => a.label.localeCompare(b.label)) ?? []
              );
            }}
            InputProps={{
              error: !!errors.find(({ field }) => field === "tags"),
              helperText: errors.find(({ field }) => field === "tags")?.message,
              label: "Tags",
            }}
            url={"/api/tags?published=1"}
            getQueryParams={(name: string) => ({ tag: name.trim() })}
            options={[]}
          />
          <CustomSelect
            onChange={(_, newValue) => setReference(newValue)}
            value={reference}
            processResponse={res => {
              return (
                res.data?.data
                  ?.map((item: any) => ({
                    id: item?.id,
                    label: item.text,
                  }))
                  ?.sort((a: any, b: any) => a.label.localeCompare(b.label)) ?? []
              );
            }}
            InputProps={{
              error: !!errors.find(({ field }) => field === "refs"),
              helperText: errors.find(({ field }) => field === "refs")?.message,
              label: "Reference",
              multiline: true,
            }}
            url={"/api/refs?published=1"}
            getQueryParams={(name: string) => ({ name: name.trim() })}
            options={[]}
          />
        </Box>
        <Divider />
        <Box display="flex" justifyContent="flex-end" sx={{ py: 3, px: 2, position: "relative", bottom: 0, gap: 2 }}>
          {showSaveButtons && (
            <Button
              onClick={() => handleSubmit(publishedStatus.readyToReview, "save")}
              disabled={loading || !quote.trim()}
              sx={{ width: 100, borderRadius: "20px" }}
              disableElevation
              color="primary"
              variant="contained"
              type="button"
            >
              {loading && clickedButton === "save" ? <CircularProgress size={16} /> : ""} Save
            </Button>
          )}
          {showSaveButtons && (
            <Button
              onClick={() => handleSubmit(publishedStatus.unpublished, "saveAsUnpublished")}
              disabled={loading || !quote.trim()}
              disableElevation
              color="primary"
              sx={theme => ({
                flex: 1,
                borderRadius: "20px",
                backgroundColor: alpha(theme.palette.primary.main, 0.5),
              })}
              variant="contained"
              type="button"
            >
              {loading && clickedButton === "saveAsUnpublished" ? <CircularProgress size={16} /> : ""} Save as Unpublished
            </Button>
          )}
          <Button disableElevation variant="outlined" sx={{ borderRadius: "20px" }} onClick={props.handleClose} type="button">
            <Typography sx={{ fontSize: "16px" }} color="textPrimary">
              Cancel
            </Typography>
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default Quotes;
