import { Box, Typography } from "@mui/material";
import React from "react";

export interface TextViewProps {
  label: string;
  value?: string | React.ReactNode | number;
  actionComponent?: React.ReactNode;
}

export const TextView = ({ label, value, actionComponent }: TextViewProps) => {
  return (
    <Box sx={{ mb: 2 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography sx={theme => ({ color: theme.palette.grey["500"] })} variant="caption">
          {label}
        </Typography>
        {actionComponent}
      </Box>
      <Typography
        sx={{
          overflowWrap: "break-word",
        }}
      >
        {value || "-"}
      </Typography>
    </Box>
  );
};
