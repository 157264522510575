import { alpha, Box, Button, CircularProgress, Divider, TextField, Typography } from "@mui/material";
import React, { FC, useEffect } from "react";
import { ILanguage, ModalType, SubmitButtonType } from "../../utils/types";
import { useCreateLanguageMutation, useEditLanguageMutation } from "../../api/apiSlice";
import toast from "react-hot-toast";

interface CreateOrEditLanguageProps {
  language?: ILanguage | null;
  handleClose: () => void;
  modalType: ModalType;
  isDrawerOpen?: boolean;
}

export const CreateOrEditLanguage: FC<CreateOrEditLanguageProps> = props => {
  const { language, handleClose, modalType } = props;

  const [languageDetails, setLanguageDetails] = React.useState<Partial<ILanguage> | null>(language || null);
  const [clickedButton, setClickedButton] = React.useState<SubmitButtonType | null>(null);
  const [error, setError] = React.useState<string | null>(null);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setLanguageDetails({ ...languageDetails, [event.target.id]: event.target.value });

    // Only show error for required fields
    if (event.target.id === "lang") {
      if (event.target.value) {
        setError(null);
        return;
      }
      setError("Language is required");
    }
  };

  const [createLanguage, createLanguageResult] = useCreateLanguageMutation();
  const [editLanguage, editLanguageResult] = useEditLanguageMutation();
  const { isLoading: isLoadingAdd, isSuccess } = createLanguageResult;
  const { isSuccess: isEditSuccess, isLoading: isEditLoading } = editLanguageResult;

  const isLoading = isLoadingAdd || isEditLoading;

  const handleSubmit = (published: -1 | 1 | 0, buttonType: SubmitButtonType) => {
    setClickedButton(buttonType);
    if (languageDetails?.lang) {
      if (modalType === "Edit" && languageDetails?.id) {
        editLanguage({
          id: languageDetails.id,
          lang: languageDetails.lang,
          published,
        })
          .unwrap()
          .then(() => {
            toast.success("Changes saved successfully");
          })
          .catch(error => {
            toast.error(error?.data || "Failed to save changes");
          });
      } else {
        createLanguage({
          lang: languageDetails.lang,
          published,
        })
          .unwrap()
          .then(() => {
            toast.success("Language added successfully");
          })
          .catch(error => {
            toast.error(error?.data || "Failed to create language");
          });
      }
    }
  };

  useEffect(() => {
    if (isSuccess || isEditSuccess) {
      handleClose();
    }
  }, [handleClose, isEditSuccess, isSuccess]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <Box gap={5} display="flex" flexDirection={"column"}>
        <TextField
          label="Language"
          id="lang"
          value={languageDetails?.lang || ""}
          error={!!error}
          helperText={error}
          onChange={handleOnChange}
          onBlur={handleOnChange}
          fullWidth
          required
          margin="normal"
        />
      </Box>
      <Box>
        <Divider />

        <Box display="flex" justifyContent="space-between" sx={{ p: 3, bottom: 0, gap: 2 }}>
          <Button
            onClick={() => handleSubmit(-1, "save")}
            disabled={isLoading || !languageDetails?.lang?.trim()}
            sx={{ width: 100, borderRadius: "20px" }}
            disableElevation
            color="primary"
            variant="contained"
            type="button"
            name="save"
          >
            {isLoading && clickedButton === "save" && <CircularProgress size={16} />} Save
          </Button>
          <Button
            onClick={() => handleSubmit(0, "saveAsUnpublished")}
            disabled={isLoading || !languageDetails?.lang?.trim()}
            sx={theme => ({ flex: 1, borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
            disableElevation
            variant="contained"
            type="button"
            name="saveAsUnpublished"
          >
            {isLoading && clickedButton === "saveAsUnpublished" && <CircularProgress size={16} />}
            Save as unpublished
          </Button>
          <Button disableElevation variant="outlined" sx={{ borderRadius: "20px" }} onClick={handleClose} type="button">
            <Typography sx={{ fontSize: "16px" }} color="textPrimary">
              Cancel
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
