import { useDispatch, useSelector } from "react-redux";
import { LOGIN_ACTION, LOGOUT_ACTION } from "../Login/loginController";
import Cookies from "universal-cookie";
import { decodedToken } from "../../utils/utils";

const cookies = new Cookies();

export interface DecodedToken {
  user: User;
  iat: number;
  exp: number;
  id: number;
}
interface User {
  id: number;
  email: string;
  googleUserId?: string;
  name?: string;
  password?: string;
  avatar?: string;
  status?: string;
  subscription?: string;
  lastLoginAt?: string;
  subscribedAt?: string;
  unsubscribedAt?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
}
export interface AuthState {
  login: any;
  register: any;
  user: User;
}

interface Action {
  type: string;
  data: any;
}
const initialState: AuthState = {
  login: {
    accessToken: localStorage?.["accessToken"],
    refreshToken: localStorage?.["refreshToken"],
  },
  register: {},
  user: {
    ...(decodedToken(localStorage?.["accessToken"]) as DecodedToken)?.user,
  },
};

export const useAuth = () => {
  const auth = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();
  const logout = () => {
    dispatch({ type: LOGOUT_ACTION });
  };
  return { auth, logout };
};

export const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case LOGIN_ACTION:
      localStorage.setItem("accessToken", action.data.accessToken);
      localStorage.setItem("refreshToken", action.data.refreshToken);
      cookies.set("accessToken", action.data.accessToken, { path: "/" });
      cookies.set("refreshToken", action.data.refreshToken, { path: "/" });
      const accessToken = action.data.accessToken || localStorage.getItem("accessToken");
      const decoded = decodedToken(accessToken) as DecodedToken;
      const user = decoded.user;

      return {
        ...state,
        login: action.data,
        user,
      };
    case LOGOUT_ACTION:
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      cookies.remove("accessToken", { path: "/" });
      cookies.remove("refreshToken", { path: "/" });
      return {
        ...state,
        login: {},
      };
    default:
      return state;
  }
};
