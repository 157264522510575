import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Table } from "../../components";
import { useMemo, useState } from "react";
import { userFriendlyDateTimestamp } from "../../utils";
import { User } from "../../utils/types";
import { useGetUserLoginHistoryQuery } from "../../api/apiSlice";

interface Props {
  user: User;
  handleClose: () => void;
}

export const ViewUserLoginHistory: React.FC<Props> = props => {
  const { user, handleClose } = props;

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const { data, isLoading, isFetching } = useGetUserLoginHistoryQuery({
    id: user.id,
    per_page: pagination.pageSize,
    page: pagination.pageIndex + 1,
  });

  const columns = useMemo(() => {
    return [
      { accessorKey: "id", header: "Id" },
      { accessorKey: "device", header: "Device", size: 300 },
      { accessorKey: "ip", header: "IP Address", size: 300 },
      {
        accessorKey: "createdAt",
        header: "Recent Activity",
        size: 300,
        Cell: ({ row }: any) => userFriendlyDateTimestamp(row.original.createdAt),
      },
    ];
  }, []);

  return (
    <Box>
      <DialogTitle>
        <Typography variant="h6">Login History</Typography>
      </DialogTitle>
      <DialogContent>
        <Table
          enableStickyHeader
          columns={columns}
          data={data?.data || []}
          manualPagination
          onPaginationChange={setPagination}
          state={{ pagination }}
          rowCount={data?.total}
          loading={isLoading || isFetching}
        />
      </DialogContent>
      <DialogActions>
        <Button disableElevation variant="outlined" sx={{ width: 100, borderRadius: "20px" }} onClick={handleClose} type="button">
          Cancel
        </Button>
      </DialogActions>
    </Box>
  );
};
