import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";

export interface Book {
  id: number;
  name: string;
  isbn?: string;
  cover?: string;
  published: number;
  createdBy: User;
  createdAt: StringDate;
  updatedBy: User;
  updatedAt: StringDate;
  deletedAt: StringDate;
  authors: Author[];
}

export interface IQuote {
  id: number;
  engText: string;
  origText: string;
  origLang: string;
  origTimePeriod: string;
  published: number;
  views: number;
  likes: number;
  hides: number;
  shares: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  tags: ITag[];
  refs: Reference[];
  cultures: ICulture[];
  sources: ISource[];
  trans: ITranslation[];
  authors: { id: number; name: string }[];
  createdBy: { id: number; name: string };
  updatedBy: { id: number; name: string };
}

export interface UserRole {
  id: number;
  role: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
}

export interface UserLike {
  id: number;
  targetId: number;
  targetType: string;
  createdAt: Date;
  updatedAt: Date;
  user: User;
}

export interface UserHide {
  id: number;
  targetId: number;
  targetType: string;
  createdAt: Date;
  updatedAt: Date;
  user: User;
}

export interface UserShare {
  targetId: number;
  targetType: string;
  createdAt: Date;
  updatedAt: Date;
  user: User;
}

export interface User {
  id: number;
  googleUserId?: string;
  appleUserId?: string;
  name: string;
  email: string;
  avatar?: string;
  status: string;
  subscription?: string;
  lastLoginAt?: Date;
  subscribedAt?: Date;
  unsubscribedAt?: Date;
  profile: any;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  tags: ITag[];
  roles: UserRole[];
  totalLikes: number;
  totalHides: number;
  totalShares: number;
}

export interface Author {
  id: number | string;
  name: string;
  avatar?: string;
  published: number;
  createdBy: User;
  createdAt: StringDate;
  updatedBy: User;
  updatedAt: StringDate;
  deletedAt?: StringDate;
  books: Book[];
}

type StringDate = string | Date;

export interface Reference {
  id: number | string;
  text: string;
  createdAt: Date;
  published: number;
  updatedBy: User;
  createdBy: User;
  updatedAt: Date;
  deletedAt: Date;
}

export interface ITag {
  id: number | string;
  tag: string;
  type: string;
  mobileHidden: boolean;
  published: number;
  createdBy: User;
  createdAt: Date;
  updatedBy: User;
  updatedAt: Date;
  deletedAt: Date;
}

export interface PreviewTag extends Omit<ITag, "published" | "updatedBy" | "createdBy"> {
  published: string;
  updatedBy: string;
  createdBy: string;
}

// Translation
export interface ITranslation {
  id: number | string;
  text: string;
  lang: string;
  published: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

//cultures
export interface ICulture {
  id: number;
  culture: string;
  icon: string;
  published: number;
  createdBy: User;
  createdAt: Date;
  updatedBy: User;
  updatedAt: Date;
  deletedAt: Date;
}

export interface IPreviewCulture extends Omit<ICulture, "published"> {
  published: string | number;
}

export interface IFileUploadHandle {
  uploadFile: () => Promise<string | null>;
}

export interface IUpdateCulture {
  id: number | string;
  culture: string;
  icon?: string;
}

// Sources
export interface ISource {
  id: number;
  name: string;
  isbn: string;
  published: number;
  cover: string;
  authors: User[];
  createdAt: Date;
  createdBy: User;
  updatedAt: Date;
  updatedBy: User;
}

export interface IPreviewSource extends Omit<ISource, "published"> {
  published: string | number;
}

// Languages
export interface ILanguage {
  id: number;
  lang: string;
  published: number;
  createdBy: User;
  createdAt: Date;
  updatedBy: User;
  updatedAt: Date;
  deletedAt: Date;
}

export interface IPageTab {
  label: TabLabelEnum;
  value: TabKeyEnum;
  params: {
    published: 1 | 0 | -1;
  };
}

export enum TabKeyEnum {
  Published = "published",
  ReadyForReview = "readyForReview",
  Unpublished = "unpublished",
}

export enum TabLabelEnum {
  Published = "Published",
  ReadyForReview = "Ready for review",
  Unpublished = "Unpublished",
}

export interface TableMenuItemInterface {
  label: string;
  onClick: () => void;
  visible: boolean;
  sx?: SxProps<Theme>;
}

export type SubmitButtonType = "save" | "saveAsUnpublished";

export enum ModalEnum {
  Create = "Create",
  Edit = "Edit",
  Preview = "Preview",
}

export type ModalType = keyof typeof ModalEnum;

export enum ConfirmDialogEnum {
  Delete = "Delete",
  Publish = "Publish",
  Unpublish = "Unpublish",
  Save = "Save",
  Activate = "Activate",
  Deactivate = "Deactivate",
}

export type ConfirmDialogType = keyof typeof ConfirmDialogEnum;

export enum TagTypeEnum {
  MainCategory = "Main Category",
  Category = "Category",
}

export enum TagVisibilityEnum {
  Visible = "Shown to User",
  Hidden = "Hidden from User",
}
