import Routes from "./Routes";
import { Toaster } from "react-hot-toast";

const App = () => {
  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            width: "100%",
            overflow: "auto",
          },
        }}
        position={"top-right"}
      />
      <Routes />
    </>
  );
};

export default App;
