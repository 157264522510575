import { alpha, Drawer as MUIDrawer, DrawerProps, useTheme, Box, IconButton, Typography } from "@mui/material";
import { FC } from "react";
import CloseIcon from "@mui/icons-material/Close";

interface Props extends DrawerProps {
  title?: string;
}
export const Drawer: FC<Props> = props => {
  const { open, onClose, children, title, sx } = props;

  const handleDrawerClose = (event: {}, reason: "backdropClick" | "escapeKeyDown") => {
    // We don't want to close the drawer on backdrop click if the title includes "Edit" or "Create"
    if ((title?.toLowerCase().includes("edit") || title?.toLowerCase().includes("create")) && reason === "backdropClick") {
      return;
    }
    onClose && onClose(event, reason);
  };

  const theme = useTheme();
  return (
    <MUIDrawer
      sx={{
        zIndex: theme.zIndex.drawer + 1,
        "& .MuiDrawer-paper": {
          background: alpha(theme.palette.background.default, 1),
          minWidth: "400px",
        },
        ...sx,
      }}
      onClose={handleDrawerClose}
      anchor="right"
      open={open}
    >
      <Box display="flex" justify-content="space-between" alignItems="center" sx={{ padding: "24px 24px 0", width: "100%" }}>
        <Typography variant="subtitle1" sx={{ flex: 1 }}>
          {title || ""}
        </Typography>
        <IconButton onClick={event => onClose && onClose(event, "escapeKeyDown")}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        display="flex"
        sx={{
          padding: "24px",
          width: "100%",
          height: "100%",
        }}
      >
        {children}
      </Box>
    </MUIDrawer>
  );
};
