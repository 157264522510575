import React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import { useAuth } from "../Auth/authController";
import { Paper, MenuList, Divider, ListItemText, Dialog } from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { ResetPassword } from "./ResetPassword";
import KeyIcon from "@mui/icons-material/Key";

interface Props {
  children: React.ReactNode;
  accountMenuTooltipTitle?: string;
}
export default function AccountMenu(props: Props) {
  const { children, accountMenuTooltipTitle } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openResetPasswordForm, setOpenResetPasswordForm] = React.useState(false);
  const { logout, auth } = useAuth();

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleResetPasswordDialog = (open: boolean) => {
    setOpenResetPasswordForm(open);
  };

  const topMenuItems = [
    {
      value: auth.user?.name || "",
      icon: <PersonOutlineIcon fontSize="small" />,
    },
    {
      value: auth.user?.email || "",
      icon: <AlternateEmailIcon fontSize="small" />,
    },
    {
      value: "Reset Password",
      icon: <KeyIcon fontSize="small" />,
      onClick: () => handleResetPasswordDialog(true),
    },
  ];
  return (
    <Box>
      <Dialog open={openResetPasswordForm} onClose={() => handleResetPasswordDialog(false)} fullWidth maxWidth="sm">
        <ResetPassword handleClose={() => handleResetPasswordDialog(false)} />
      </Dialog>
      <Tooltip title={accountMenuTooltipTitle || "Account"}>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          {children}
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Paper sx={{ width: 320, maxWidth: "100%" }}>
          <MenuList>
            {topMenuItems.map((item, index) => (
              <MenuItem style={{ cursor: "default" }} key={index} onClick={item.onClick}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText>{item.value}</ListItemText>
              </MenuItem>
            ))}
            <Divider />
            <MenuItem onClick={logout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </MenuList>
        </Paper>
      </Menu>
    </Box>
  );
}
