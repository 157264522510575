import React from "react";
import { Box, Typography, Chip, alpha, Button, Divider } from "@mui/material";
import { Author, TabKeyEnum } from "../../utils/types";
import { userFriendlyDateTimestamp } from "../../utils";
import { TextView } from "../../components/TextView/TextView";
import { useHasPermission } from "../../utils/hooks";
import { UserPermissions } from "../../utils/permissions";

interface ViewAuthorProps {
  author: Author | null;
  activeTab: TabKeyEnum;
  handleClose: () => void;
  handleEditAuthor: () => void;
  handlePublishAuthor: () => void;
  handleUnpublishAuthor: () => void;
  handleReviewAuthor: () => void;
}

export const ViewAuthor: React.FC<ViewAuthorProps> = props => {
  const { author, activeTab, handleClose, handleEditAuthor, handlePublishAuthor, handleReviewAuthor } = props;

  const canApproveAuthor = useHasPermission(UserPermissions.EDIT_PUBLISHED_AUTHORS);
  const canEditReadyAndUnPublishedAuthors = useHasPermission(UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_AUTHORS);

  const showPublishButton = activeTab === TabKeyEnum.ReadyForReview && canApproveAuthor;
  const showEditButton = [TabKeyEnum.ReadyForReview, TabKeyEnum.Unpublished].includes(activeTab) && canEditReadyAndUnPublishedAuthors;
  const showReadyForReviewButton = activeTab === TabKeyEnum.Unpublished && canEditReadyAndUnPublishedAuthors;

  if (!author) {
    return (
      <Typography variant="h5" component="h2">
        No author selected
      </Typography>
    );
  }

  const alignButtonsEnd = !showPublishButton && !showEditButton && !showReadyForReviewButton;

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ width: "100%" }}>
      <Box>
        <TextView label="Author" value={author.name} />
        <TextView label="Published" value={author?.published} />
        <TextView label="Created By" value={author?.createdBy?.name} />
        <TextView label="Updated By" value={author?.updatedBy?.name} />
        <TextView label="Created At" value={userFriendlyDateTimestamp(author?.createdAt)} />
        <TextView label="Updated At" value={userFriendlyDateTimestamp(author?.updatedAt)} />
        {author?.deletedAt && <TextView label="Deleted At" value={userFriendlyDateTimestamp(author?.deletedAt)} />}
        <TextView
          label={`Books by ${author.name}`}
          value={author?.books?.length ? author?.books?.map(book => <Chip key={book?.id} label={book?.name} />) : "No books listed"}
        />
      </Box>
      <Box sx={{ gap: 2 }} display="flex" flexDirection="column" justifyContent="space-between">
        <Divider />
        <Box
          display="flex"
          justifyContent={alignButtonsEnd ? "flex-end" : "space-between"}
          sx={{ position: "relative", bottom: 0, gap: 3 }}
        >
          {showPublishButton && (
            <Button
              onClick={handlePublishAuthor}
              sx={theme => ({ borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
              disableElevation
              color="primary"
              variant="contained"
              type="button"
            >
              Approve & Publish
            </Button>
          )}
          {showReadyForReviewButton && (
            <Button
              onClick={handleReviewAuthor}
              sx={theme => ({ borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
              disableElevation
              color="primary"
              variant="contained"
              type="button"
            >
              Ready for Review
            </Button>
          )}
          {showEditButton && (
            <Button
              onClick={handleEditAuthor}
              sx={{ flex: 1, borderRadius: "20px" }}
              disableElevation
              color="primary"
              variant="contained"
              type="button"
            >
              Edit
            </Button>
          )}
          <Button disableElevation variant="outlined" sx={{ borderRadius: "20px" }} onClick={handleClose} type="button">
            <Typography sx={{ fontSize: "16px" }} color="textPrimary">
              Cancel
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
