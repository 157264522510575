export const debounce = (func: Function, wait: number) => {
  let timeout: NodeJS.Timeout | undefined;

  return function executedFunction(...args: any[]) {
    const later = () => {
      timeout = undefined;
      func(...args);
    };

    if (timeout !== undefined) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(later, wait);
  };
};
