import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useLogin } from "./loginController";

const Login: React.FC = () => {
  const { formData, handleChange, handleSubmit, errors, loading } = useLogin();
  return (
    <Box sx={{ pb: 4 }}>
      <Typography variant="subtitle1" sx={{ mb: 5 }}>
        Login
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Email"
          variant="standard"
          name="email"
          value={formData.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Password"
          type="password"
          variant="standard"
          name="password"
          error={!!errors.password}
          helperText={errors.password}
          value={formData.password}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <Button
          sx={{ mt: 8, width: "100%", textTransform: "none" }}
          disableElevation
          disabled={loading}
          variant="contained"
          color="primary"
          size="large"
          type="submit"
        >
          {loading ? <CircularProgress size={30} /> : `Sign In`}
        </Button>
      </form>
    </Box>
  );
};

export default Login;
