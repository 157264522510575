import { FC } from "react";

import { Box } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
}

export const CustomTabPanel: FC<TabPanelProps> = props => {
  const { children, value, index, ...other } = props;

  return (
    <Box role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </Box>
  );
};
