import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { alpha, Button, CircularProgress, Dialog, DialogActions, DialogContent, Drawer, Theme, Typography } from "@mui/material";
import { CustomTabs, Table } from "../../components";
import AddQuotes from "./AddQuotes";
import ViewQuote from "./ViewQuote";
import { useParams } from "../../components/Table/Table";
import { useQuotes } from "./quotesController";
import { ActionType, useGetQuotesTableColumns } from "./useGetQuotesTableColumns";
import { UserPermissions } from "../../utils/permissions";
import { useHasPermission } from "../../utils/hooks";
import { CreateOrEditTranslations } from "./CreateOrEditTranslations";

type ConfirmDialog = {
  title: "Publish" | "UnPublish" | "Remove" | "Review";
  body: string;
  open: boolean;
};

export default function Quotes() {
  const [searchParams] = useSearchParams();
  const [activeDrawer, setActiveDrawer] = useState<ActionType | null>(null);
  const [hoveredRow, setHoveredRow] = useState(-1);
  const [activeDialogItem, setActiveDialogItem] = useState<any>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialog>({
    title: "Publish",
    body: "Are you sure you want to publish this?.",
    open: false,
  });

  const dispatch = useDispatch();
  const { pagination, params, setPagination, setUrlParams } = useParams();
  const state = useQuotes(
    {
      ...params,
      per_page: pagination.pageSize,
      page: pagination.pageIndex + 1,
    },
    dispatch,
  );
  const { removeQuote, remove, updateQuote, creating } = state;

  const handleChangeTab = (newValue: number) => {
    setUrlParams("activeTab", newValue?.toLocaleString());
  };
  const handleCreateOpen = () => {
    setActiveDrawer("Edit");
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setActiveDialogItem(null);
  };

  const handleEdit = (item: any) => {
    setActiveDrawer("Edit");
    setDrawerOpen(true);
    setActiveDialogItem(item);
  };

  const handleView = (item: any) => {
    setActiveDrawer("View");
    setDrawerOpen(true);
    setActiveDialogItem(item);
  };

  const handleTableAction = (action: ActionType, item: any) => {
    switch (action) {
      case "View":
        handleView(item);
        break;
      case "Publish":
        handlePublish(item);
        break;
      case "UnPublish":
        handleUnPublish(item);
        break;
      case "Edit":
        handleEdit(item);
        break;
      case "Remove":
        handleRemove(item);
        break;
      case "EditTranslation":
        setActiveDrawer(action);
        setDrawerOpen(true);
        setActiveDialogItem(item);
        break;
    }
  };

  const openConfirmDialog = (content: Omit<ConfirmDialog, "open">) => {
    setConfirmDialog(item => ({ ...item, ...content, open: true }));
  };

  const closeConfirmDialog = () => {
    setConfirmDialog(item => ({ ...item, open: false }));
  };

  const handlePublish = (item: any) => {
    openConfirmDialog({
      title: "Publish",
      body: "Are you sure you want to publish this?",
    });
    setActiveDialogItem(item);
  };

  const handleUnPublish = (item: any) => {
    openConfirmDialog({
      title: "UnPublish",
      body: "Are you sure you want to unpublish this?",
    });
    setActiveDialogItem(item);
  };

  const handleRemove = (item: any) => {
    openConfirmDialog({
      title: "Remove",
      body: "Are you sure you want to Remove this? This action is permanent and cannot be undone.",
    });
    setDrawerOpen(false);
    setActiveDialogItem(item);
  };

  const handleReview = (item: any) => {
    openConfirmDialog({
      title: "Review",
      body: "Are you sure you want to send this for review?",
    });
    setActiveDialogItem(item);
  };

  const handleDialogConfirm = async () => {
    const item = activeDialogItem;

    if (confirmDialog.title === "Remove") {
      const { error } = await removeQuote(item);
      if (!error) {
        closeConfirmDialog();
      }
      return;
    }

    // For Review tab (activeTab 1), quotes can be published, 1 value
    // For Unpublished tab (activeTab 2), quotes can be sent for Review, -1 value
    // For Published tab (activeTab 0), they can be Unpublished, 0 value
    const publishChange =
      confirmDialog.title === "Review" && activeTab === "2" ? -1 : confirmDialog.title === "Publish" && activeTab === "1" ? 1 : 0;

    const body = {
      id: item.id,
      engText: item.engText,
      origText: item.origText,
      origLang: item.origLang,
      sources: item.sources?.map(({ id }: any) => id),
      cultures: item.cultures?.map(({ id }: any) => id),
      authors: item.authors?.map(({ id }: any) => id),
      tags: item.tags?.map(({ id }: any) => id),
      refs: item.refs?.map(({ id }: any) => id),
      published: publishChange,
    };
    const res = await updateQuote(body);
    if (!res?.error) {
      closeConfirmDialog();
      handleDrawerClose();
    }
  };

  const onSubmit = async (data: any) => {
    const { createQuote, updateQuote } = state;
    const action = Object.keys(activeDialogItem || {}).length ? updateQuote : createQuote;
    const res = await action(data);
    if (!res?.error) {
      return handleDrawerClose();
    }
  };
  const activeTab = searchParams.get("activeTab") ?? "0";
  const { data = [], loading, total } = state as any;
  const columns = useGetQuotesTableColumns({
    activeTab,
    setHoveredRow,
    hoveredRow,
    handleTableAction,
  });
  const tableProps = {
    columns,
    data,
    manualPagination: true,
    onPaginationChange: setPagination,
    state: { pagination },
    rowCount: total,
    loading,
    muiTableHeadCellProps: {
      sx: {
        textTransform: "uppercase",
      },
    },
    muiTableBodyRowProps: ({ row }: any) => ({
      onClick: () => {
        handleView(row.original._original);
      },
      onMouseEnter: () => {
        setHoveredRow(row.index);
      },
      onMouseLeave: () => {
        setHoveredRow(-1);
      },
      hover: false,
      sx: (theme: Theme) => {
        return {
          "&:hover": {
            backgroundColor: `${theme.palette.primary.main} !important`,
          },
        };
      },
    }),
  };
  const tabs: any = [
    {
      label: "Published",
      component: <Table {...tableProps} />,
    },
    {
      label: "Ready for Review",
      component: <Table {...tableProps} />,
    },
    {
      label: "Unpublished",
      component: <Table {...tableProps} />,
    },
  ];

  const canCreateQuotes = useHasPermission(UserPermissions.CREATE_QUOTES);

  return (
    <>
      <ConfirmDialogComponent
        content={confirmDialog}
        onConfirm={handleDialogConfirm}
        onClose={closeConfirmDialog}
        loading={confirmDialog.title === "Remove" ? remove.loading : creating.loading}
      />
      <Drawer
        sx={(theme: any) => ({
          // background: theme.palette.background.default,
          zIndex: theme.zIndex.drawer + 1,
          "& .MuiDrawer-paper": {
            background: alpha(theme.palette.background.default, 1),
          },
        })}
        onClose={handleDrawerClose}
        anchor="right"
        open={drawerOpen}
        ModalProps={{
          hideBackdrop: activeDrawer === "Edit" ? true : false,
          disableEscapeKeyDown: activeDrawer === "Edit" ? true : false,
        }}
      >
        {activeDrawer === "Edit" && (
          <AddQuotes
            onSubmit={onSubmit}
            loading={state.creating.loading}
            title={activeDialogItem ? "Edit Quote" : "Add Quote"}
            initialValues={activeDialogItem}
            handleClose={handleDrawerClose}
          />
        )}
        {activeDrawer === "View" && (
          <ViewQuote
            onEdit={handleEdit}
            onPublish={handlePublish}
            onUnpublish={handleUnPublish}
            onReadyForReview={handleReview}
            loading={state.creating.loading}
            title="Preview"
            values={activeDialogItem}
            handleClose={handleDrawerClose}
          />
        )}
        {activeDrawer === "EditTranslation" && activeDialogItem && (
          <CreateOrEditTranslations initialValues={activeDialogItem.trans} quoteId={activeDialogItem?.id} handleClose={handleDrawerClose} />
        )}
      </Drawer>
      <CustomTabs
        activeTab={parseInt(searchParams.get("activeTab") || "0")}
        buttonLabel={canCreateQuotes ? "CREATE QUOTE" : undefined}
        tabs={tabs}
        buttonClick={handleCreateOpen}
        onTabChange={handleChangeTab}
      />
    </>
  );
}

type ConfirmDialogComponentProps = {
  content: ConfirmDialog;
  onConfirm: () => void;
  onClose: () => void;
  loading: boolean;
};

const ConfirmDialogComponent = ({ content, onConfirm, onClose, loading }: ConfirmDialogComponentProps) => {
  const { title, body, open } = content;
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogContent sx={{ p: 3 }}>
        <Typography sx={{ fontSize: 24, mb: 2 }}>{title}</Typography>
        <Typography sx={{ mb: 2 }}>{body}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={(theme: any) => ({ color: theme.palette.text.primary })}>
          Cancel
        </Button>
        <Button onClick={onConfirm}>
          {loading ? <CircularProgress size={16} /> : null}
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
