import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Dispatch } from "redux";
import { OneLiner } from "../../components/Table/Table";
import toast from "react-hot-toast";

interface Tag {
  id: number;
  tag: string;
  type: string;
  published: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

interface Reference {
  id: number;
  text: string;
  published: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

interface Culture {
  id: number;
  culture: string;
  icon: string | null;
  published: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

interface Source {
  id: number;
  name: string;
  isbn: string;
  cover: string | null;
  published: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

interface Translation {
  id: number;
  text: string;
  lang: string;
  published: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

interface Quote {
  id: number;
  engText: string;
  origText: string;
  origLang: string;
  origTimePeriod: string;
  published: boolean;
  views: number;
  likes: number;
  hides: number;
  shares: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  tags: Tag[];
  refs: Reference[];
  cultures: Culture[];
  sources: Source[];
  trans: Translation[];
  authors: { id: number; name: string }[];
  createdBy: { id: number; name: string };
  updatedBy: { id: number; name: string };
}

interface State {
  loading: boolean;
  error: any;
  data: { data: Quote[]; total: number };
}

interface Action {
  type: string;
  data: any;
}
const initialState: State = {
  loading: false,
  error: null,
  data: { data: [], total: 10 },
};

const FETCH_QUOTES = "FETCH_QUOTES";
const LOAD_QUOTES = "LOAD_QUOTES";
const LOAD_QUOTES_ERROR = "LOAD_QUOTES_ERROR";
const UPDATE_QUOTE = "UPDATE_QUOTE";

const defaultSortParams = {
  sortBy: "updatedAt",
  sortOrder: "DESC",
};

const baseUrl = "";
async function fetchQuotes({ activeTab = "0", ...params }: any, onSuccess: any, onError: any) {
  try {
    const published = [1, -1, 0][parseInt(activeTab)];
    const { data } = await axios.get(`${baseUrl}/api/quotes`, {
      withCredentials: true,
      params: {
        ...params,
        ...defaultSortParams,
        published,
      },
    });
    onSuccess(data);
    return { data };
  } catch (error: any) {
    onError(error?.response?.data);
    return { error };
  }
}

const createQuotesApi = (body: any) =>
  axios.post(`${baseUrl}/api/quotes`, body, {
    withCredentials: true,
  });

const updateQuotesApi = ({ id, ...body }: any) =>
  axios.put(`${baseUrl}/api/quotes/${id}`, body, {
    withCredentials: true,
  });

export const useQuotes = (params: any, dispatch: Dispatch) => {
  const [creating, setCreating] = useState({
    loading: false,
    error: null,
    response: null,
  });
  const { data: rawData, loading } = useSelector((state: { quotes: State }) => state?.quotes);
  const { page, status, per_page, activeTab } = params;
  const fetchFunc = useCallback(
    function ({ page, status, per_page, activeTab }: any) {
      const validParams = { page, status, per_page, activeTab };
      const onSuccess = (data: any) => {
        dispatch({
          type: LOAD_QUOTES,
          data,
        });
      };
      const onError = (error: any) => {
        dispatch({
          type: LOAD_QUOTES_ERROR,
          data: error,
        });
      };
      dispatch({ type: FETCH_QUOTES });
      fetchQuotes(validParams, onSuccess, onError);
    },
    [dispatch],
  );

  const updateStore = (data: Quote) => {
    return {
      data,
      type: UPDATE_QUOTE,
    };
  };

  const createQuote = async (data: any) => {
    setCreating(v => ({ ...v, loading: true }));
    try {
      const response = (await createQuotesApi(data)) as any;
      setCreating(v => ({ ...v, response }));
      fetchFunc({ page, status, per_page, activeTab });
      toast.success("Quote added successfully");
      return response;
    } catch (error: any) {
      setCreating(v => ({ ...v, error }));
      toast.error(error?.response?.data || "Failed to create quote");
      return { error };
    } finally {
      setCreating(v => ({ ...v, loading: false }));
    }
  };

  const updateQuote = async (data: any) => {
    setCreating(v => ({ ...v, loading: true }));
    const MessageMap: any = {
      1: {
        success: "Quote published successfully",
        error: "Failed to publish quote",
      },
      0: {
        success: "Quote unpublished successfully",
        error: "Failed to unpublish quote",
      },
    };
    try {
      const response = (await updateQuotesApi(data)) as any;
      setCreating(v => ({ ...v, response }));
      dispatch(updateStore(response.data));
      fetchFunc({ page, status, per_page, activeTab });
      toast.success(MessageMap[data.published]?.success || "Changes saved successfully");
      return response;
    } catch (error: any) {
      setCreating(v => ({ ...v, error }));
      toast.error(error?.response?.data || MessageMap[data.published]?.error || "Failed to save changes");
      return { error };
    } finally {
      setCreating(v => ({ ...v, loading: false }));
    }
  };

  useEffect(() => {
    const validParams = { page, status, per_page, activeTab };
    fetchFunc(validParams);
  }, [activeTab, fetchFunc, page, per_page, status]);

  const translationColumns: any = {};

  const data = rawData?.data?.map(quote => {
    const translations = quote?.trans?.reduce((previous, current) => {
      translationColumns[current.lang] = {
        accessorKey: current.lang,
        header: current.lang,
        size: 400,
        Cell: ({ row }: any) => <OneLiner text={row.original[current.lang]} />,
      };
      return {
        ...previous,
        [current.lang]: current.text,
      };
    }, {});
    return {
      id: quote.id,
      quote: quote.engText,
      originCulture: quote?.cultures?.[0]?.culture,
      originTimePeriod: quote.origTimePeriod,
      author: quote.authors.map(({ name }) => name).join(", "),
      tags: quote.tags,
      reference: quote.refs?.[0]?.text,
      createdAt: quote.createdAt,
      updatedAt: quote.updatedAt,
      totalViews: quote.views,
      totalLikes: quote.likes,
      totalShares: quote.shares,
      totalHides: quote.hides,
      createdBy: quote?.createdBy?.name,
      updatedBy: quote?.updatedBy?.name,
      source: quote.sources.map(({ name }) => name).join(", "),
      ...translations,
      _original: quote,
    };
  });

  const [remove, setRemove] = useState<{ loading: boolean; error: any; data: any }>({
    loading: false,
    error: null,
    data: null,
  });
  const removeQuote = async ({ id }: Quote) => {
    setRemove(v => ({ ...v, loading: true }));
    try {
      const { data } = await axios.delete(`${baseUrl}/api/quotes/${id}`);
      fetchFunc({ page, status, per_page, activeTab });
      setRemove(v => ({ ...v, data, loading: false }));
      toast.success("Quote removed successfully");
      return { ...remove, error: null, data, loading: false };
    } catch (error: any) {
      setRemove(v => ({ ...v, error, loading: false }));
      toast.error(error?.response?.data || error?.message);
      return { ...remove, data: null, error };
    }
  };
  return {
    data,
    total: rawData.total,
    createQuote,
    columns: Object.values(translationColumns),
    creating,
    loading,
    updateQuote,
    removeQuote,
    remove,
  };
};

export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case FETCH_QUOTES:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LOAD_QUOTES:
      return {
        ...state,
        loading: false,
        data: action.data,
        error: null,
      };
    case UPDATE_QUOTE:
      return {
        ...state,
        data: {
          ...state.data,
          data: state.data.data.map((item: any) => (item.id === action.data.id ? action.data : item)),
        },
      };
    case LOAD_QUOTES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.data ?? null,
      };
    default:
      return state;
  }
}
