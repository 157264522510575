import {
  alpha,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC, useEffect } from "react";
import { useGetUserQuery, useUpdateProfileMutation } from "../../api/apiSlice";
import toast from "react-hot-toast";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { validatePassword } from "../../utils";

interface Props {
  handleClose: () => void;
}

type PasswordField = "currentPassword" | "newPassword" | "confirmPassword";

type ShowPasswords = {
  currentPassword: boolean;
  newPassword: boolean;
  confirmPassword: boolean;
};

export const ResetPassword: FC<Props> = props => {
  const { handleClose } = props;

  const [passwordDetails, setPasswordDetails] = React.useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = React.useState<{ field: string; message: string }[]>([]);
  const [showPasswords, setShowPasswords] = React.useState<ShowPasswords>({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPasswordDetails({ ...passwordDetails, [event.target.id]: event.target.value });

    if (event.target.id === "currentPassword") {
      if (event.target.value) {
        setErrors(errors.filter(error => error.field !== "currentPassword"));
        return;
      }
      setErrors([...errors, { field: "currentPassword", message: "Current password is required" }]);
    }

    if (event.target.id === "newPassword") {
      const { isValid, message } = validatePassword(event.target.value);
      if (isValid) {
        setErrors(errors.filter(error => error.field !== "newPassword"));
        return;
      }
      setErrors([...errors, { field: "newPassword", message }]);
    }

    if (event.target.id === "confirmPassword") {
      if (passwordDetails.newPassword === event.target.value) {
        setErrors(errors.filter(error => error.field !== "confirmPassword"));
        return;
      }
      setErrors([...errors, { field: "confirmPassword", message: "Passwords do not match" }]);
    }
  };

  const { data: userData } = useGetUserQuery({});
  const [updateProfile, updateProfileResult] = useUpdateProfileMutation();
  const { isSuccess, isLoading } = updateProfileResult;

  const handleSubmit = () => {
    updateProfile({
      id: userData.id,
      oldPwd: passwordDetails.currentPassword,
      newPwd: passwordDetails.newPassword,
    })
      .unwrap()
      .then(() => {
        toast.success("Your password was successfully changed");
      })
      .catch(error => {
        toast.error(error?.data || "Failed to change password");
      });
  };

  const handleClickShowPassword = (field: PasswordField) => {
    setShowPasswords({ ...showPasswords, [field]: !showPasswords[field] });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [handleClose, isSuccess]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      <DialogTitle>
        <Typography variant="h6">Update password</Typography>
      </DialogTitle>
      <DialogContent>
        <Box gap={1} display="flex" flexDirection={"column"}>
          <TextField
            label="Current Password"
            id="currentPassword"
            value={passwordDetails.currentPassword}
            error={!!errors.find(err => err.field === "currentPassword")}
            helperText={errors.find(err => err.field === "currentPassword")?.message}
            onChange={handleOnChange}
            onBlur={handleOnChange}
            fullWidth
            required
            margin="normal"
            type={showPasswords.currentPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("currentPassword")}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPasswords.currentPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="New Password"
            id="newPassword"
            value={passwordDetails.newPassword}
            error={!!errors.find(err => err.field === "newPassword")}
            helperText={errors.find(err => err.field === "newPassword")?.message}
            onChange={handleOnChange}
            onBlur={handleOnChange}
            fullWidth
            required
            margin="normal"
            type={showPasswords.newPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("newPassword")}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPasswords.newPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Confirm Password"
            id="confirmPassword"
            value={passwordDetails.confirmPassword}
            error={!!errors.find(err => err.field === "confirmPassword")}
            helperText={errors.find(err => err.field === "confirmPassword")?.message}
            onChange={handleOnChange}
            onBlur={handleOnChange}
            fullWidth
            required
            margin="normal"
            type={showPasswords.confirmPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("confirmPassword")}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPasswords.confirmPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Box display="flex" justifyContent="space-between" sx={{ position: "relative", bottom: 0, gap: 2 }}>
          <Button
            onClick={() => handleSubmit()}
            disabled={isLoading || errors.length > 0 || Object.values(passwordDetails).some(val => !val)}
            sx={theme => ({ width: 100, borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
            disableElevation
            color="primary"
            variant="contained"
            type="button"
            name="save"
          >
            {isLoading ? <CircularProgress size={16} /> : ""} Save
          </Button>
          <Button disableElevation variant="outlined" sx={{ borderRadius: "20px" }} onClick={handleClose} type="button">
            <Typography sx={{ fontSize: "16px" }} color="textPrimary">
              Cancel
            </Typography>
          </Button>
        </Box>
      </DialogActions>
    </Box>
  );
};
