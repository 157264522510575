import { Box, Button, Tab, Tabs } from "@mui/material";
import * as React from "react";
import { useDeleteAuthorMutation, useEditAuthorMutation, useGetAuthorsQuery } from "../../api/apiSlice";
import { useGetAuthorsTableColumns } from "./useGetAuthorsTableColumns";
import { Table, CustomTabPanel, ConfirmDialog } from "../../components";
import { type MRT_ColumnDef } from "material-react-table";
import { useLocation, useNavigate } from "react-router-dom";
import { appendSearchParams, PageTabParams } from "../../utils";
import { Author, ModalEnum, ModalType, TabKeyEnum } from "../../utils/types";
import { Drawer } from "../../components/Drawer/Drawer";
import { ViewAuthor } from "./ViewAuthor";
import { CreateOrEditAuthor } from "./CreateOrEditAuthor";
import toast from "react-hot-toast";
import { useHasPermission } from "../../utils/hooks";
import { UserPermissions } from "../../utils/permissions";

export const Authors = () => {
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const [hoveredRow, setHoveredRow] = React.useState(-1);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<ModalType | undefined>();
  const [selectedAuthor, setSelectedAuthor] = React.useState<Author | null>(null);
  const [isUnpublishDialogOpen, setIsUnpublishDialogOpen] = React.useState(false);
  const [isPublishDialogOpen, setIsPublishDialogOpen] = React.useState(false);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = React.useState(false);
  const [isReviewDialogOpen, setIsReviewDialogOpen] = React.useState(false);
  const [filter, setFilter] = React.useState("");

  const [editAuthor, editAuthorResult] = useEditAuthorMutation();
  const [deleteAuthor, deleteAuthorResult] = useDeleteAuthorMutation();

  const canCreateAuthor = useHasPermission(UserPermissions.CREATE_AUTHORS);

  const location = useLocation();
  const navigate = useNavigate();
  const [urlParams] = React.useState(new URLSearchParams(location.search));

  const activeTabParam = (urlParams.get("activeTab") ?? PageTabParams[0].value) as TabKeyEnum;
  const activeTab = Math.max(
    0,
    PageTabParams.findIndex(({ value }) => value === activeTabParam),
  );

  const apiSupportedParams = PageTabParams[activeTab].params;
  const { data, isLoading, isFetching } = useGetAuthorsQuery({
    ...apiSupportedParams,
    name: filter,
    page: pagination.pageIndex + 1,
    per_page: pagination.pageSize,
  });

  const handlePreviewAuthor = () => {
    setIsDrawerOpen(ModalEnum.Preview);
  };

  const handleCreateAuthor = () => {
    setHoveredRow(-1);
    setSelectedAuthor(null);
    setIsDrawerOpen(ModalEnum.Create);
  };

  const handleEditAuthor = () => {
    setIsDrawerOpen(ModalEnum.Edit);
  };

  const handleToggleUnpublishAuthorModal = (open: boolean) => {
    setIsUnpublishDialogOpen(open);
  };

  const handleTogglePublishAuthorModal = (open: boolean) => {
    setIsPublishDialogOpen(open);
  };

  const handleToggleRemoveAuthorModal = (open: boolean) => {
    setIsRemoveDialogOpen(open);
  };

  const handleToggleReviewAuthorModal = (open: boolean) => {
    setIsReviewDialogOpen(open);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(undefined);
  };

  const columns = useGetAuthorsTableColumns({
    activeTab: activeTabParam,
    setHoveredRow,
    hoveredRow,
    handlePreviewAuthor,
    handleEditAuthor,
    handleUnpublishAuthor: () => handleToggleUnpublishAuthorModal(true),
    handlePublishAuthor: () => handleTogglePublishAuthorModal(true),
    handleRemoveAuthor: () => handleToggleRemoveAuthorModal(true),
  });

  const authorsState = React.useMemo(() => data?.data || [], [data]);
  const authorsData = React.useMemo<MRT_ColumnDef<Author>[]>(
    () =>
      authorsState?.map((author: Author) => ({
        id: author.id,
        avatar: author.avatar,
        name: author.name,
        createdAt: author.createdAt,
        createdBy: author.createdBy,
        updatedBy: author.updatedBy,
        updatedAt: author.updatedAt,
        books: author.books,
        published: author.published === 0 ? "Unpublished" : author.published === -1 ? "Ready for review" : "Published",
      })),
    [authorsState],
  );

  const total = data?.total || 0;

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    const activeTab = PageTabParams[newValue].value;
    const newUrlParams = appendSearchParams({ activeTab }, urlParams);
    navigate(`${location.pathname}?${newUrlParams.toString()}`, {
      replace: true,
    });
  };

  const handleRemoveAuthor = () => {
    if (selectedAuthor?.id) {
      deleteAuthor({ id: selectedAuthor.id })
        .unwrap()
        .then(() => {
          handleToggleRemoveAuthorModal(false);
          toast.success("Author removed successfully");
        })
        .catch(error => {
          toast.error(error?.response?.data || "Failed to remove author");
        });
    } else {
      console.error("No Author selected");
    }
  };

  const handlePublishChange = (published: TabKeyEnum) => {
    const messages = {
      [TabKeyEnum.Published]: {
        value: 1,
        success: "Author published successfully",
        error: "Failed to publish author",
      },
      [TabKeyEnum.ReadyForReview]: {
        value: -1,
        success: "Author saved successfully",
        error: "Failed to saved author",
      },
      [TabKeyEnum.Unpublished]: {
        value: 0,
        success: "Author unpublish successfully",
        error: "Failed to unpublish author",
      },
    };

    if (selectedAuthor?.id) {
      editAuthor({
        id: selectedAuthor.id,
        name: selectedAuthor.name,
        published: messages[published].value,
      })
        .unwrap()
        .then(() => {
          handleToggleUnpublishAuthorModal(false);
          handleTogglePublishAuthorModal(false);
          handleToggleReviewAuthorModal(false);
          handleDrawerClose();
          toast.success(messages[published].success);
        })
        .catch(error => {
          toast.error(error?.response?.data || messages[published].error);
        });
    }
  };

  const getDrawerTitle = () => {
    if (isDrawerOpen === ModalEnum.Preview) {
      return "Preview Author";
    }
    if (isDrawerOpen === ModalEnum.Create) {
      return "Create Author";
    }
    if (isDrawerOpen === ModalEnum.Edit) {
      return "Edit Author";
    }
    return "";
  };

  return (
    <>
      <Drawer title={getDrawerTitle()} onClose={handleDrawerClose} open={!!isDrawerOpen} sx={{ width: "500px", height: "100%" }}>
        {isDrawerOpen === ModalEnum.Preview && (
          <ViewAuthor
            author={selectedAuthor}
            activeTab={activeTabParam}
            handleClose={handleDrawerClose}
            handleEditAuthor={handleEditAuthor}
            handleReviewAuthor={() => handleToggleReviewAuthorModal(true)}
            handlePublishAuthor={() => handleTogglePublishAuthorModal(true)}
            handleUnpublishAuthor={() => handleToggleUnpublishAuthorModal(true)}
          />
        )}
        {(isDrawerOpen === ModalEnum.Create || isDrawerOpen === ModalEnum.Edit) && (
          <CreateOrEditAuthor handleClose={handleDrawerClose} author={selectedAuthor} isEditUser={isDrawerOpen === ModalEnum.Edit} />
        )}
      </Drawer>
      <ConfirmDialog
        open={isUnpublishDialogOpen}
        title={`Unpublish Author: ${selectedAuthor?.name}`}
        body={`Are you sure you want to unpublish this tag?`}
        onClose={() => handleToggleUnpublishAuthorModal(false)}
        onConfirm={() => handlePublishChange(TabKeyEnum.Unpublished)}
        loading={editAuthorResult.isLoading}
      />
      <ConfirmDialog
        open={isPublishDialogOpen}
        title={`Publish Author: ${selectedAuthor?.name}`}
        body={`Are you sure you want to publish this Author?`}
        onClose={() => handleTogglePublishAuthorModal(false)}
        onConfirm={() => handlePublishChange(TabKeyEnum.Published)}
        loading={editAuthorResult.isLoading}
      />
      <ConfirmDialog
        open={isRemoveDialogOpen}
        title={`Remove Author: ${selectedAuthor?.name}`}
        body={`Are you sure you want to remove this Author? This action is permanent and cannot be undone.`}
        onClose={() => handleToggleRemoveAuthorModal(false)}
        onConfirm={handleRemoveAuthor}
        loading={deleteAuthorResult.isLoading}
      />
      <ConfirmDialog
        open={isReviewDialogOpen}
        title={`Save Author: ${selectedAuthor?.name}`}
        body={`Are you sure you want to save this Author?`}
        onClose={() => handleToggleReviewAuthorModal(false)}
        onConfirm={() => handlePublishChange(TabKeyEnum.ReadyForReview)}
        loading={editAuthorResult.isLoading}
      />
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Tabs value={activeTab} onChange={handleTabChange}>
            {PageTabParams.map((value, index: number) => {
              return (
                <Tab
                  key={value.label}
                  label={value.label}
                  sx={{
                    p: 2,
                    mr: 2,
                  }}
                />
              );
            })}
          </Tabs>
          {canCreateAuthor && (
            <Box>
              <Button sx={{ borderRadius: 0.5 }} color="primary" variant="outlined" onClick={handleCreateAuthor}>
                CREATE AUTHOR
              </Button>
            </Box>
          )}
        </Box>

        <Box sx={{ width: "100%" }}>
          <CustomTabPanel value={activeTab} index={activeTab}>
            <Table
              columns={columns}
              data={authorsData}
              manualPagination
              onPaginationChange={setPagination}
              manualFiltering
              onGlobalFilterChange={setFilter}
              state={{ pagination }}
              rowCount={total}
              loading={isLoading || isFetching}
              muiTableBodyRowProps={({ row }: { row: { id: string; index: number; original: Author } }) => ({
                onMouseEnter: () => {
                  setHoveredRow(row.index);
                  setSelectedAuthor(row.original);
                },
                onMouseLeave: () => {
                  setHoveredRow(-1);
                },
                onClick: (event: any) => {
                  event.stopPropagation();
                  setSelectedAuthor(row.original);
                  handlePreviewAuthor();
                },
                hover: false,
                sx: (theme: any) => ({
                  backgroundColor: hoveredRow === row.index ? theme.palette.primary.main : "inherit",
                  ":hover": {
                    backgroundColor: theme.palette.primary.main,
                  },
                }),
              })}
            />
          </CustomTabPanel>
        </Box>
      </Box>
    </>
  );
};
