import React from "react";
import { Dialog, DialogContent, Typography, DialogActions, Button, CircularProgress } from "@mui/material";

interface ConfirmDialogProps {
  open: boolean;
  title: string;
  body: string;
  onClose: () => void;
  onConfirm: () => void;
  loading: boolean;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ open, title, body, onClose, onConfirm, loading }) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogContent sx={{ p: 3 }}>
        <Typography sx={{ fontSize: 24, mb: 2 }}>{title}</Typography>
        <Typography sx={{ mb: 2 }}>{body}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={(theme: any) => ({ color: theme.palette.text.primary })}>
          Cancel
        </Button>
        <Button onClick={onConfirm}>
          {loading ? <CircularProgress size={16} /> : null}
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
