import { IPageTab, TabLabelEnum, TabKeyEnum } from "./types";

export const PageTabParams: IPageTab[] = [
  {
    label: TabLabelEnum.Published,
    value: TabKeyEnum.Published,
    params: {
      published: 1,
    },
  },
  {
    label: TabLabelEnum.ReadyForReview,
    value: TabKeyEnum.ReadyForReview,
    params: {
      published: -1,
    },
  },
  {
    label: TabLabelEnum.Unpublished,
    value: TabKeyEnum.Unpublished,
    params: {
      published: 0,
    },
  },
];

export const publishedStatus = {
  published: 1,
  unpublished: 0,
  readyToReview: -1,
};
