import React from "react";
import { alpha, Box, Button, Divider, Typography } from "@mui/material";
import { ILanguage, TabKeyEnum, TabLabelEnum } from "../../utils/types";
import { userFriendlyDateTimestamp } from "../../utils";
import { TextView, TextViewProps } from "../../components/TextView/TextView";
import { useHasPermission } from "../../utils/hooks";
import { UserPermissions } from "../../utils/permissions";

interface Props {
  activeTab: TabKeyEnum;
  language: ILanguage | null;
  handleClose: () => void;
  handleEditLanguage: () => void;
  handlePublishLanguage: () => void;
  handleUnpublishLanguage: () => void;
  handleReviewLanguage: () => void;
}

interface TextViewInterface extends TextViewProps {
  visible?: boolean;
}

export const ViewLanguage: React.FC<Props> = props => {
  const { language, handleClose, handleEditLanguage, handlePublishLanguage, handleReviewLanguage, activeTab } = props;

  const canEdit = useHasPermission(UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_LANGUAGES);
  const canApprovePublish = useHasPermission(UserPermissions.APPROVE_PUBLISH_LANGUAGES);

  if (!language) {
    return (
      <Typography variant="h5" component="h2">
        No language selected
      </Typography>
    );
  }

  const editButtonVisible = [TabKeyEnum.ReadyForReview, TabKeyEnum.Unpublished].includes(activeTab) && canEdit;
  const publishButtonVisible = activeTab === TabKeyEnum.ReadyForReview && canApprovePublish;
  const reviewButtonVisible = activeTab === TabKeyEnum.Unpublished && canEdit;

  const textViews: TextViewInterface[] = [
    { label: "Language", value: language.lang },
    {
      label: "Published",
      value:
        language.published === 0
          ? TabLabelEnum.Unpublished
          : language.published === -1
            ? TabLabelEnum.ReadyForReview
            : TabLabelEnum.Published,
    },
    { label: "Created At", value: userFriendlyDateTimestamp(language.createdAt) },
    { label: "Created By", value: language.createdBy?.name },
    { label: "Updated At", value: userFriendlyDateTimestamp(language.updatedAt) },
    { label: "Updated By", value: language.updatedBy?.name },
  ];

  const alignButtonsEnd = !publishButtonVisible && !reviewButtonVisible && !editButtonVisible;

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ width: 400 }}>
      <Box>
        {textViews.map(textView =>
          textView.visible === undefined || textView.visible ? (
            <TextView key={textView.label} label={textView.label} value={textView.value} />
          ) : null,
        )}
      </Box>
      <Box sx={{ gap: 2 }} display="flex" flexDirection="column" justifyContent="space-between">
        <Divider />
        <Box
          display="flex"
          justifyContent={alignButtonsEnd ? "flex-end" : "space-between"} // If no buttons are visible, align to the right for cancel button
          sx={{ position: "relative", bottom: 0, gap: 3 }}
        >
          {publishButtonVisible && (
            <Button
              onClick={handlePublishLanguage}
              sx={theme => ({ borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
              disableElevation
              color="primary"
              variant="contained"
              type="button"
            >
              Approve & Publish
            </Button>
          )}
          {reviewButtonVisible && (
            <Button
              onClick={handleReviewLanguage}
              sx={theme => ({ borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
              disableElevation
              color="primary"
              variant="contained"
              type="button"
            >
              Ready for Review
            </Button>
          )}
          {editButtonVisible && (
            <Button
              onClick={handleEditLanguage}
              sx={{ flex: 1, borderRadius: "20px" }}
              disableElevation
              color="primary"
              variant="contained"
              type="button"
            >
              Edit
            </Button>
          )}
          <Button disableElevation variant="outlined" sx={{ borderRadius: "20px" }} onClick={handleClose} type="button">
            <Typography sx={{ fontSize: "16px" }} color="textPrimary">
              Cancel
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
