import { alpha, Box, Button, CircularProgress, Divider, TextField, Typography } from "@mui/material";
import React, { FC, useEffect } from "react";
import { Author, SubmitButtonType } from "../../utils/types";
import { useCreateAuthorMutation, useEditAuthorMutation } from "../../api/apiSlice";
import toast from "react-hot-toast";

interface CreateOrEditAuthorProps {
  author?: Author | null;
  handleClose: () => void;
  isEditUser?: boolean;
}
export const CreateOrEditAuthor: FC<CreateOrEditAuthorProps> = props => {
  const { author, handleClose, isEditUser } = props;

  const [isEditing, setIsEditing] = React.useState<boolean>(isEditUser || false);

  const [authorDetails, setAuthorDetails] = React.useState<Partial<Author> | null>(author || null);
  const [clickedButton, setClickedButton] = React.useState<SubmitButtonType | null>(null);
  const [error, setError] = React.useState<string | null>(null);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setAuthorDetails({ ...authorDetails, name: event.target.value });
    if (event.target.value) {
      setError(null);
      return;
    }
    setError("Author is required");
  };

  useEffect(() => {
    setIsEditing(isEditUser || false);
  }, [isEditUser]);

  const [createUser, createUserResult] = useCreateAuthorMutation();
  const [editUser, editUserResult] = useEditAuthorMutation();
  const { isLoading: isLoadingAddUser, isSuccess } = createUserResult;
  const { isSuccess: isEditSuccess, isLoading: isEditLoading } = editUserResult;

  const isLoading = isLoadingAddUser || isEditLoading;

  const handleSubmit = (published: -1 | 1 | 0, buttonType: SubmitButtonType) => {
    setClickedButton(buttonType);
    if (authorDetails?.name) {
      if (isEditing && authorDetails?.id) {
        editUser({
          id: authorDetails.id,
          name: authorDetails.name,
          published,
        })
          .unwrap()
          .then(() => {
            toast.success("Changes saved successfully");
          })
          .catch(error => {
            toast.error(error?.data || "Failed to save changes");
          });
      } else {
        createUser({
          name: authorDetails.name,
          published,
        })
          .unwrap()
          .then(() => {
            toast.success("Author added successfully");
          })
          .catch(error => {
            toast.error(error?.data || "Failed to create author");
          });
      }
    }
  };

  useEffect(() => {
    if (isSuccess || isEditSuccess) {
      handleClose();
    }
  }, [handleClose, isEditSuccess, isSuccess]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={{
        width: "100%",
      }}
    >
      <TextField
        label="Author"
        value={authorDetails?.name || ""}
        onChange={handleOnChange}
        onBlur={handleOnChange}
        fullWidth
        required
        error={!!error}
        helperText={error}
        margin="normal"
      />
      <Box>
        <Divider />

        <Box display="flex" justifyContent="flex-end" sx={{ p: 3, position: "relative", bottom: 0, gap: 2 }}>
          <Button
            // saves default as ready for review -1
            onClick={() => handleSubmit(-1, "save")}
            disabled={isLoading || !authorDetails?.name?.trim()}
            sx={{ borderRadius: "20px" }}
            disableElevation
            color="primary"
            variant="contained"
            type="button"
          >
            {isLoading && clickedButton === "save" ? <CircularProgress size={16} /> : ""} Save
          </Button>
          <Button
            onClick={() => handleSubmit(0, "saveAsUnpublished")}
            disabled={isLoading || !authorDetails?.name?.trim()}
            sx={theme => ({ flex: 1, borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
            disableElevation
            color="primary"
            variant="contained"
            type="button"
          >
            {isLoading && clickedButton === "saveAsUnpublished" ? <CircularProgress size={16} /> : ""} Save as Unpublished
          </Button>
          <Button disableElevation variant="outlined" sx={{ borderRadius: "20px" }} onClick={handleClose} type="button">
            <Typography sx={{ fontSize: "16px" }} color="textPrimary">
              Cancel
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
