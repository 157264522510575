import axios from "axios";

export const upload = async (file: File | undefined, onProgressChange: (progress: number) => void) => {
  try {
    if (file) {
      const formData = new FormData();
      formData.append("files", file);
      const { data } = await axios.post("/api/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
        onUploadProgress: (progressEvent: any) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          onProgressChange(percentCompleted);
        },
      });
      return { data };
    }
    return { error: { message: "File is required" } };
  } catch (error) {
    return { error };
  }
};
