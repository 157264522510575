import { FC, useState } from "react";

import { MaterialReactTable, MRT_RowData, useMaterialReactTable } from "material-react-table";
import { useLocation, useNavigate } from "react-router-dom";

import { Tooltip, Typography } from "@mui/material";

interface TableProps extends MRT_RowData {
  columns: any[];
  data: any[];
  onPaginationChange?: (...args: any) => void;
  state?: any;
}

export function paramsToObject(entries: any) {
  const result: any = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}
export const useParams = () => {
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [urlParams, setUrlParams] = useState(new URLSearchParams(location.search));
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  const updateParams = (key: string, value: string) => {
    const p = urlParams;
    p.set(key, value);
    setUrlParams(p);
    navigate(`?${p.toString()}`);
  };
  return { pagination, params, setPagination, setUrlParams: updateParams };
};

export const OneLiner: FC<{ text: string }> = ({ text }) => {
  return (
    <Tooltip key={text} title={text} arrow>
      <Typography
        noWrap
        sx={() => ({
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          cursor: "pointer",
        })}
      >
        {text}
      </Typography>
    </Tooltip>
  );
};

export const Table: FC<TableProps> = ({ columns, data, state, loading, muiTableBodyRowProps, ...rest }) => {
  const table = useMaterialReactTable({
    columns,
    //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    data,
    //enable some features
    enableColumnResizing: true,
    enableColumnActions: true,
    enableRowActions: true,
    positionActionsColumn: "first",
    displayColumnDefOptions: {
      "mrt-row-actions": { header: "" },
    },
    state: {
      ...state,
      isLoading: loading,
      columnPinning: { right: ["action"] },
    },
    muiTableBodyCellProps: {
      sx: {
        minHeight: "56px",
        paddingTop: 0,
        paddingBottom: 0,
        boxShadow: "none",
        backgroundColor: "inherit",
      },
    },
    muiTableBodyRowProps: (args: any) => ({
      hover: false,
      sx: (theme: any) => ({
        ...(typeof muiTableBodyRowProps?.(args)?.sx === "function"
          ? muiTableBodyRowProps?.(args)?.sx(theme)
          : muiTableBodyRowProps?.(args)?.sx),
        "&:hover": {
          backgroundColor: `${theme.palette.primary.main} !important`,
        },
      }),
      ...muiTableBodyRowProps?.(args),
    }),
    ...rest,
  });

  return <MaterialReactTable table={table} />;
};
