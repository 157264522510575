import { differenceInMinutes, differenceInSeconds, format, isToday, isYesterday } from "date-fns";

export const userFriendlyDateTimestamp = (date: string | Date): string => {
  const nowDate: Date = new Date();
  const dateValue: Date = new Date(date);

  if (!date) {
    return "-";
  }

  if (differenceInSeconds(nowDate, dateValue) < 60) {
    return "Just Now";
  }

  if (differenceInMinutes(nowDate, dateValue) < 5) {
    return "A few minutes ago";
  }

  if (differenceInMinutes(nowDate, dateValue) < 60) {
    return `${differenceInMinutes(nowDate, dateValue)} minutes ago`;
  }

  if (isToday(dateValue)) {
    return `Today, ${format(dateValue, "h:mm a")}`;
  }

  if (isYesterday(dateValue)) {
    return `Yesterday, ${format(dateValue, "h:mm a")}`;
  }

  return `${format(dateValue, "dd MMM, yyyy")}`;
};
