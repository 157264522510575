import { RoleValue } from "../../api/api.types";

export enum UserTabEnum {
  WebOnly = "webOnly",
  AllActive = "allActive",
  MobileOnly = "mobileOnly",
  InActive = "inActive",
  MobileUsers = "mobileUsers",
}

export enum UserStatus {
  Active = "active",
  Inactive = "inactive",
}

export enum UserLabel {
  WebOnly = "Web Only",
  AllActive = "All Active",
  MobileOnly = "Mobile Only",
  Inactive = "Inactive",
  MobileUsers = "Mobile Users",
}

export type UserParams = {
  status: UserStatus;
  roles?: RoleValue[];
};

export type UserTabParam = {
  label: UserLabel;
  value: UserTabEnum;
  params: UserParams;
};
