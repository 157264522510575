import { MenuItem } from "@mui/material";
import { ActionCell } from "../../components/ActionCell/ActionCell";
import { OneLiner } from "../../components/Table/Table";
import { userFriendlyDateTimestamp } from "../../utils";
import { TabKeyEnum, TableMenuItemInterface } from "../../utils/types";
import { MouseEvent, useCallback, useState } from "react";
import { UserPermissions } from "../../utils/permissions";
import { useHasPermission } from "../../utils/hooks";

interface Params {
  activeTab: TabKeyEnum;
  setHoveredRow: (rowIndex: number) => void;
  hoveredRow: number;
  handleEditCulture: () => void;
  handleUnpublishCulture: () => void;
  handlePublishCulture: () => void;
  handleReviewCulture: () => void;
  handlePreviewCulture: () => void;
  handleRemoveCulture: () => void;
}

export const useGetCulturesTableColumns = (params: Params) => {
  const {
    setHoveredRow,
    hoveredRow,
    activeTab,
    handlePreviewCulture,
    handleEditCulture,
    handleUnpublishCulture,
    handlePublishCulture,
    handleRemoveCulture,
  } = params;

  const [shouldCloseMenuSelection, setShouldCloseMenuSelection] = useState<boolean>(false);

  const handlePreviewCultureSelection = useCallback(() => {
    setShouldCloseMenuSelection(true);
    handlePreviewCulture();
  }, [handlePreviewCulture, setShouldCloseMenuSelection]);

  const handleEditSelectedCulture = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handleEditCulture();
    },
    [handleEditCulture, setShouldCloseMenuSelection],
  );

  const handleUnpublishSelectedCulture = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handleUnpublishCulture();
    },
    [handleUnpublishCulture],
  );

  const handlePublishSelectedCulture = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handlePublishCulture();
    },
    [handlePublishCulture],
  );

  const handleRemoveSelectedCulture = useCallback(
    (event?: MouseEvent<HTMLElement>) => {
      event?.stopPropagation?.();
      setShouldCloseMenuSelection(true);
      handleRemoveCulture();
    },
    [handleRemoveCulture, setShouldCloseMenuSelection],
  );

  const canEditReadyAndUnPublishedCultures = useHasPermission(UserPermissions.EDIT_READY_FOR_REVIEW_AND_UNPUBLISHED_CULTURES);
  const canApproveCultures = useHasPermission(UserPermissions.APPROVE_PUBLISH_CULTURES);
  const canRemoveCultures = useHasPermission(UserPermissions.REMOVE_CULTURES);

  const menuItems: TableMenuItemInterface[] = [
    {
      label: "Approve & Publish",
      onClick: handlePublishSelectedCulture,
      visible: activeTab === TabKeyEnum.ReadyForReview && canApproveCultures,
    },
    {
      label: "Preview",
      onClick: handlePreviewCultureSelection,
      visible: true,
    },
    {
      label: "Edit",
      onClick: handleEditSelectedCulture,
      visible: [TabKeyEnum.ReadyForReview, TabKeyEnum.Unpublished].includes(activeTab) && canEditReadyAndUnPublishedCultures,
    },
    {
      label: "Unpublish",
      onClick: handleUnpublishSelectedCulture,
      visible: [TabKeyEnum.Published, TabKeyEnum.ReadyForReview].includes(activeTab) && canApproveCultures,
    },
    {
      label: "Remove",
      onClick: handleRemoveSelectedCulture,
      visible: [TabKeyEnum.ReadyForReview, TabKeyEnum.Unpublished].includes(activeTab) && canRemoveCultures,
      sx: { color: "red" },
    },
  ];

  const columns = [
    {
      accessorKey: "icon",
      header: "Icon",
      size: 200,
      Cell: ({ row }: any) =>
        row.original.icon ? <img alt="icon" height={30} src={row.original.icon ? `/api${row.original.icon}` : ""} loading="lazy" /> : null,
    },
    { accessorKey: "culture", header: "Culture", size: 300 },
    {
      accessorKey: "createdAt",
      header: "Created At",
      Cell: ({ row }: any) => userFriendlyDateTimestamp(row.original.createdAt),
    },
    {
      accessorKey: "createdBy",
      header: "Created By",
      Cell: ({ row }: any) => <OneLiner text={row.original.createdBy?.name} />,
    },
    {
      accessorKey: "updatedAt",
      header: "Updated At",
      Cell: ({ row }: any) => userFriendlyDateTimestamp(row.original.updatedAt),
    },
    {
      accessorKey: "updatedBy",
      header: "Updated By",
      Cell: ({ row }: any) => <OneLiner text={row.original.updatedBy?.name} />,
    },
    {
      accessorKey: "action",
      id: "action",
      size: 50,
      header: "",
      enableResizing: false,
      muiTableHeadCellProps: () => ({
        sx: {
          visibility: "hidden",
        },
      }),
      Cell: ({ row }: any) => {
        if (hoveredRow !== row.index) return null;
        return (
          <ActionCell
            shouldCloseMenuSelection={shouldCloseMenuSelection}
            setShouldCloseMenuSelection={setShouldCloseMenuSelection}
            hovered={hoveredRow === row.index}
            loading={false}
            activeTab={activeTab}
            onActionButtonClick={(event: MouseEvent<HTMLElement>) => {
              event?.stopPropagation?.();
              setHoveredRow(row.index);
            }}
          >
            {menuItems.map(
              (item, index) =>
                item.visible && (
                  <MenuItem key={index} sx={item.sx} onClick={item.onClick}>
                    {item.label}
                  </MenuItem>
                ),
            )}
          </ActionCell>
        );
      },
    },
  ];

  return columns;
};
