import { Box, Button, Tabs, Tab } from "@mui/material";
import React from "react";
import { ReactNode } from "react";
import { CustomTabPanel } from "../CustomTabPanel/CustomTabPanel";

interface TabInterface {
  label: string;
  component: ReactNode;
}
interface Props {
  tabs: TabInterface[];
  buttonLabel?: string;
  buttonClick?: () => void;
  onTabChange?: (value: number) => void;
  activeTab?: number;
}
export default function CustomTab(props: Props) {
  const [value, setValue] = React.useState(props.activeTab || 0);
  const { tabs, buttonClick, buttonLabel, onTabChange } = props;
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (onTabChange) {
      onTabChange(newValue);
    }
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box display="flex" justifyContent="space-between">
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          {tabs.map(({ label }, index) => (
            <Tab
              key={index}
              label={label}
              sx={{
                mr: 2,
                p: 2,
              }}
            />
          ))}
        </Tabs>
        {buttonLabel && (
          <Box>
            <Button sx={{ borderRadius: 0.5 }} color="primary" variant="outlined" onClick={buttonClick}>
              {buttonLabel || "Create New"}
            </Button>
          </Box>
        )}
      </Box>
      {tabs.map((tab, index) => {
        return (
          <CustomTabPanel key={index} value={value} index={index}>
            {tab.component}
          </CustomTabPanel>
        );
      })}
    </Box>
  );
}
