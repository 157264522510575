import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { Box, CircularProgress, Menu } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import type { MouseEvent as ReactMouseEvent } from "react";

import { useStyles } from "./actionCellStyles";

interface Props {
  loading: boolean;
  hovered: boolean;
  onActionButtonClick: (event: ReactMouseEvent<HTMLElement>) => void;
  onClickEditUser?: () => void;
  activeTab?: string;
  children: React.ReactNode;
  shouldCloseMenuSelection: boolean;
  setShouldCloseMenuSelection: (value: boolean) => void;
}
export const ActionCell: React.FC<Props> = props => {
  const { loading, hovered, onActionButtonClick, setShouldCloseMenuSelection, shouldCloseMenuSelection, children } = props;

  const [actionCellAnchorElement, setActionCellAnchorElement] = useState<HTMLElement | null>(null);

  const onMenuClose = useCallback(
    (event: ReactMouseEvent<HTMLElement, MouseEvent>) => {
      event.stopPropagation();

      setActionCellAnchorElement(null);
      setShouldCloseMenuSelection(false);
    },
    [setShouldCloseMenuSelection],
  );

  useEffect(() => {
    if (shouldCloseMenuSelection) {
      // create a synthetic event to pass to onMenuClose.
      // This won't be used, but we are intrested with onClose event below when the backdrop is clicked to stop the propagation to the parent.
      // This will prevent the parent (table) from opening the drawer view when row is clicked.
      const event = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
      }) as unknown as ReactMouseEvent<HTMLElement>;

      onMenuClose(event);
    }
  }, [onMenuClose, shouldCloseMenuSelection]);

  const handleActionButtonClick = (event: ReactMouseEvent<HTMLElement>) => {
    onActionButtonClick(event);
  };

  const classes = useStyles();

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={actionCellAnchorElement}
        open={Boolean(actionCellAnchorElement)}
        onClose={onMenuClose} // This is the function that will be called when the backdrop is clicked.
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {children}
      </Menu>

      {loading ? (
        <span data-testid="actionIconSpinner">
          <CircularProgress />
        </span>
      ) : (
        hovered && (
          <Box
            className={classes.actionIcon}
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            data-testid="actionIcon"
            data-cy="icoAction"
            sx={{ overflow: "visible" }}
            onClick={(event: ReactMouseEvent<HTMLElement>) => {
              setActionCellAnchorElement(event.currentTarget);
              handleActionButtonClick(event);
            }}
          >
            <MoreHorizOutlinedIcon fontSize="small" />
          </Box>
        )
      )}
    </>
  );
};
