import { reducer as auth } from "../containers/Auth/authController";
import quotes from "../containers/Quotes/quotesController";
import { api } from "../api/apiSlice";

interface RootReducer {
  auth: any;
  quotes: any;
  [api.reducerPath]: any; // Add the api.reducerPath property
}

const reducer: RootReducer = {
  auth,
  quotes,
  [api.reducerPath]: api.reducer,
};

export default reducer;
