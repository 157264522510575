import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";
import { useRegister } from "./registerController";

const Register: React.FC = () => {
  const { formData, handleChange, handleSubmit, errors } = useRegister();
  return (
    <Box sx={{ py: 8 }}>
      <Typography variant="subtitle1" sx={{ mb: 5 }}>
        Register
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Name"
          variant="standard"
          name="name"
          value={formData.name}
          error={!!errors.name}
          helperText={errors.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          variant="standard"
          name="email"
          value={formData.email}
          error={!!errors.email}
          helperText={errors.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Password"
          type="password"
          variant="standard"
          name="password"
          value={formData.password}
          onChange={handleChange}
          error={!!errors.password}
          helperText={errors.password}
          fullWidth
          margin="normal"
        />
        {/* <TextField
          label="Avatar URL"
          variant="standard"
          name="avatar"
          value={formData.avatar}
          onChange={handleChange}
          fullWidth
          margin="normal"
        /> */}
        <Button sx={{ mt: 4, width: "100%", textTransform: "none" }} disableElevation variant="contained" color="primary" type="submit">
          Register
        </Button>
      </form>
    </Box>
  );
};

export default Register;
