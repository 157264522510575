import { useGetUserQuery } from "../api/apiSlice";
import { canAccess, UserPermissions } from "./permissions";
import { UserRole } from "./types";

export const useHasPermission = (requiredPermission: UserPermissions) => {
  const { data: userData } = useGetUserQuery({});
  const userRoles = userData?.roles?.map((role: UserRole) => role.role) || [];

  return canAccess(userRoles, requiredPermission);
};

export const useHasAdminRole = () => {
  const { data: userData } = useGetUserQuery({});
  return userData?.roles?.some((role: UserRole) => role.role?.toLowerCase() === "admin");
};
