import { jwtDecode } from "jwt-decode";

export const decodedToken = (token: string) => {
  try {
    return jwtDecode(token);
  } catch (error) {
    return null;
  }
};

export const appendSearchParams = (newParams: any, searchParams: URLSearchParams) => {
  Object.entries(newParams).forEach(([key, value]: any[]) => {
    searchParams.set(key, value);
  });
  return searchParams.toString();
};

export const getNameFromPath = (path: string): string => {
  const pathSplitArray = path.split("/");
  return pathSplitArray[pathSplitArray.length - 1];
};

export const validatePassword = (password: string): { isValid: boolean; message: string } => {
  // Minimum 8 characters, at least 1 Alphabet uppercase or lowercase, 1 Number and 1 Special Character
  const strongRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
  const isValid = strongRegex.test(password);
  const message = isValid
    ? ""
    : "Password must be at least 8 characters long and contain at least 1 Alphabet, 1 Number and 1 Special Character";
  return { isValid, message };
};
