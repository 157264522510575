import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  capitalizedCell: {
    "&.MuiTableCell-root": {
      textTransform: "capitalize",
    },
  },
  actionCell: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    "&.MuiTableCell-root": {
      minWidth: 0,
      maxWidth: 0,
      paddingLeft: 0,
      paddingRight: 0,
      position: "sticky",
      right: 0,
      overflow: "visible !important",
    },
  },
  actionIcon: {
    width: 32,
    height: 32,
    borderRadius: "50%",
    border: "1px solid #C0C0C0",
    background: "#F4F4F4",
    cursor: "pointer",
    color: "#000",
    marginLeft: "-1rem",
  },
}));
