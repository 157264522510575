import {
  alpha,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC, useCallback, useEffect } from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ConfirmDialogEnum, ConfirmDialogType, User } from "../../utils/types";
import { useUpdateUserMutation } from "../../api/apiSlice";
import toast from "react-hot-toast";
import { RoleValue } from "../../api/api.types";
import { ConfirmDialog } from "../../components/Dialogs/ConfirmDialog";
import { validatePassword } from "../../utils";
import { VisibilityOff, Visibility } from "@mui/icons-material";

interface CreateOrEditUserProps {
  user?: User | null;
  handleClose: () => void;
}

// admin,creator,approver,translator -- Implement api in backend to return the roles.
const roles: RoleValue[] = ["Admin", "Creator", "Approver", "Translator", "Mobile User"];

export const CreateOrEditUser: FC<CreateOrEditUserProps> = props => {
  const { user, handleClose } = props;

  const [userDetails] = React.useState<Partial<User> | null>(user || null);
  const [initialUserDetails, setInitialUserDetails] = React.useState<Partial<User> | null>(null);
  const defaultRoles = userDetails?.roles?.map(role => role.role);
  const [selectedRoles, setSelectedRoles] = React.useState<string[]>(defaultRoles || []);
  const [resetPassword, setResetPassword] = React.useState<string>("");
  const [openedConfirmDialog, setOpenedConfirmDialog] = React.useState<ConfirmDialogType | undefined>();
  const [error, setError] = React.useState<string | null>(null);
  const [roleError, setRoleError] = React.useState<string | null>(null);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [disableSaveBuuton, setDisableSaveButton] = React.useState<boolean>(true);

  const handleOnChangeRoles = (_event: React.SyntheticEvent<Element, Event>, values: any) => {
    setSelectedRoles(values);

    if (values.length === 0) {
      setRoleError("At least one role should be selected");
    } else {
      setRoleError(null);
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setResetPassword(event.target.value);

    const { isValid, message } = validatePassword(event.target.value);
    if (!isValid && event.target.value) {
      setError(message);
    } else {
      setError(null);
    }
  };

  const [updateUser, updateUserResult] = useUpdateUserMutation();
  const { isSuccess: isEditSuccess, isLoading: isUpdatingLoading } = updateUserResult;

  const handleSubmit = () => {
    if (userDetails?.id) {
      updateUser({
        id: userDetails?.id,
        password: resetPassword,
        roles: selectedRoles as RoleValue[],
      })
        .unwrap()
        .then(() => {
          toast.success("User has been successfully updated");
        })
        .catch(error => {
          toast.error(error?.data || "Failed to update user");
        });
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const hasChanges = useCallback(() => {
    const userRoles = userDetails?.roles?.map(role => role.role) || [];
    const rolesChanged = userRoles.sort().join(",") !== selectedRoles.sort().join(",");

    return (
      rolesChanged ||
      Object.entries(userDetails || {}).some(([key, value]) => {
        if (key === "roles") return false; // Skip roles comparison here
        const typedKey = key as keyof Partial<User>;
        return value !== initialUserDetails?.[typedKey];
      }) ||
      resetPassword !== ""
    );
  }, [initialUserDetails, resetPassword, selectedRoles, userDetails]);

  useEffect(() => {
    const shouldDisable = !hasChanges() || isUpdatingLoading || !!error || !!roleError;
    setDisableSaveButton(shouldDisable);
  }, [error, hasChanges, isUpdatingLoading, roleError]);

  useEffect(() => {
    if (isEditSuccess) {
      handleClose();
    }
  }, [handleClose, isEditSuccess]);

  useEffect(() => {
    if (userDetails) {
      setInitialUserDetails(userDetails);
    }
  }, [userDetails]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="space-between">
      {openedConfirmDialog === ConfirmDialogEnum.Save && (
        <ConfirmDialog
          open={true}
          title="Confrim"
          body={`Are you sure you want to update this User: ${userDetails?.name}?`}
          onClose={() => setOpenedConfirmDialog(undefined)}
          onConfirm={() => handleSubmit()}
          loading={isUpdatingLoading}
        />
      )}
      <DialogTitle>
        <Typography variant="h6">Edit user {userDetails?.name}</Typography>
      </DialogTitle>
      <DialogContent>
        <Box gap={3} display="flex" flexDirection={"column"}>
          <Autocomplete
            multiple
            disableCloseOnSelect
            disableClearable
            id="roles-selector"
            options={roles}
            onChange={handleOnChangeRoles}
            defaultValue={defaultRoles}
            isOptionEqualToValue={(option, value) => option?.toLowerCase() === value?.toLowerCase()}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option?.toUpperCase()}
              </li>
            )}
            renderInput={params => (
              <TextField required error={!!roleError} helperText={roleError} variant="standard" {...params} label="User roles" />
            )}
          />
          <TextField
            label="Reset password"
            id="password"
            value={resetPassword}
            error={!!error}
            helperText={error}
            onChange={handleOnChange}
            onBlur={handleOnChange}
            fullWidth
            size="small"
            margin="normal"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword()}
                    onMouseDown={event => event.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disableElevation
          disabled={isUpdatingLoading}
          variant="outlined"
          sx={{ width: 100, borderRadius: "20px" }}
          onClick={handleClose}
          type="button"
        >
          Cancel
        </Button>
        <Button
          onClick={() => setOpenedConfirmDialog(ConfirmDialogEnum.Save)}
          disabled={disableSaveBuuton}
          sx={theme => ({ width: 100, borderRadius: "20px", backgroundColor: alpha(theme.palette.primary.main, 0.5) })}
          disableElevation
          color="primary"
          variant="contained"
          type="button"
        >
          Save
        </Button>
      </DialogActions>
    </Box>
  );
};
